<div id="container-video-wrapper" [ngClass]="fullscreenState ? 'fullscreen-active' : 'fullscreen-false'">
    <div class="container-video">
        <div class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9" [ngClass]="{
      'vjs-fullscreen': fullscreenState,
      'videoScreen': true,
      'dynamicVideo': isMobile == false,
      'dynamicVideoMobile': isMobile
    }">
            <div class="container-full" *ngIf="!videoErr">
                <video #videoPlayer id="{{ videoID }}" [autoplay]="autoplay" oncontextmenu="return false;"></video>
                <audio #audioPlayer id="{{ audioID }}" loop></audio>
            </div>
        </div>
    </div>
    <app-video-playlist #videoPlaylist [playlist]="playlistIndex" (playlistIndex)="doSwitchVideo($event)"></app-video-playlist>
</div>

<app-scaling-screen #videoScalingScreen [scalingMovementLookup]="scalingMovementLookup" [filteredMovement]="filteredMovement"></app-scaling-screen>