<section class="pb-4">
    <form class="referral-form" [formGroup]="referralForm">
        <ng-container formArrayName="referralGroup" *ngFor="let item of referralGroup.controls; index as i">
            <div class="input-group">
                <ng-container [formGroupName]="i">
                    <header>Invite {{ i + 1 }}</header>
                    <div>
                        <input [ngClass]="{ 'is-invalid': submitted && item?.get('firstName')?.errors }" formControlName="firstName" placeholder="First name" (change)="trimSpaces(item.get('firstName'))">
                        <div *ngIf="submitted && item?.get('firstName')?.errors" class="is-invalid-msg">First name is required</div>
                    </div>
                    <div>
                        <input [ngClass]="{ 'is-invalid': submitted && item?.get('email')?.errors }" formControlName="email" placeholder="Email" (change)="trimSpaces(item.get('email'))">
                        <div *ngIf="submitted && item?.get('email')?.errors" class="is-invalid-msg">Please enter a valid email address</div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <button class="btn-invite" type="button" [disabled]="maxInviteReached" (click)="addReferralInputs()">
            <span [injectComponent]='true' [inlineSVG]="'assets/images/PlusCircle.svg'"></span> Invite someone else
        </button>
        <button class="submit" type="submit" (click)="submitForm($event)">Submit</button>
    </form>
</section>