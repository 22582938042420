import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

interface recurlyInputs {
  number: {
    valid: boolean;
    message: string;
  };
  month: {
    valid: boolean;
    message: string;
  };
  year: {
    valid: boolean;
    message: string;
  };
  cvv: {
    valid: boolean;
    message: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  constructor(
    private http: HttpClient
  ) { }

  headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );

  apiUrl = environment.apiUrl;

  // Variables used for checkout-chips, registration & payment method
  showOptions: boolean = false;
  planSelected;
  selectedIndex: number;

  productInfoBS = new BehaviorSubject<any>(null);
  productInfo$ = this.productInfoBS.asObservable();

  paymentErrorsBS = new BehaviorSubject<any[]>([]);
  paymentErrors$ = this.paymentErrorsBS.asObservable();

  recurlyInputFieldsBS = new BehaviorSubject<recurlyInputs>({
    number: {
      valid: false,
      message: 'Enter your credit card number',
    },
    month: {
      valid: false,
      message: 'Enter a valid expiration date',
    },
    year: {
      valid: false,
      message: 'Enter a valid expiration date',
    },
    cvv: {
      valid: false,
      message: 'Enter a valid CVV',
    }
  });
  recurlyInputFields$ = this.recurlyInputFieldsBS.asObservable();


  productInformation: {
    plan: string,
    price: number,
    description: string,
    promoCode: string,
    legal: string,
  };

  /**
   * Recurly General
  */

  getRecurlyApiKey(): Observable<{ message: string }> {
    // Retrieve Required Keys or Tokens for Payment
    let apiEndpoint = `${this.apiUrl}/billing/crm-token-e`;
    return this.http.get<any>(apiEndpoint, {
      headers: this.headers,
      responseType: 'text' as 'json'
    }).pipe(
      map((data) => ({ message: data }))
    );
  }

  /**
   * Credit Card
  */

  submitCreditCardOrder(data): Observable<any> {
      let apiEndpoint = `${this.apiUrl}/billing/purchase`;
      return this.http
        .post<any>(apiEndpoint, data, {
          headers: this.headers
        })

  }

  parseBillingForm(form) {
    let data = {};
    // Parse through the submission form to send - change to form controls later after implementation
    Array.from(form.elements).forEach((element) => {
      let input = <HTMLInputElement>element;
      if(input.name && input.value) {
        data[input.name] = input.value
      }
    });
    return data;
  }

  /**
   * Apple Pay
   */
  submitApplePayOrder(data): Observable<any> {
    let apiEndpoint = `${this.apiUrl}/billing/apple-pay/purchase`;
    return this.http
      .post<any>(apiEndpoint, data, {
        headers: this.headers
      })
  }

  applePayEnabled(): Observable<any> {
    let apiEndpoint = `${this.apiUrl}/billing/applepay-enabled`;
    return this.http
      .get<any>(apiEndpoint, {
        headers: this.headers
      }).pipe(map((data) => ({ message: data })))
  }

  /**
   * Google Pay
  */
  submitGooglePayOrder(data): Observable<any> {
    let apiEndpoint = `${this.apiUrl}/billing/purchase/googlepay`;
    return this.http
      .post<any>(apiEndpoint, data, {
        headers: this.headers,
        responseType: 'text' as 'json'
      })
      .pipe(map((data) => ({ message: data })))
  }

  /**
   * Paypal
  */
  getPayPalAuth(data): Observable<any> {
    // Authenticate paypal order to get token
    let apiEndpoint = `${this.apiUrl}/billing/paypal/authorize`;
    return this.http.post<any>(apiEndpoint, data);
  }

  submitPayPalOrder(data): Observable<any> {
    // Submit purchase order after getting valid token
    let apiEndpoint = `${this.apiUrl}/billing/paypal/pay`;
    return this.http.post<any>(apiEndpoint, data);
  }

  /**
   * Venmo
  */
  submitVenmoOrder(data): Observable<any> {
    let apiEndpoint = `${this.apiUrl}/billing/purchase/venmo`;
    return this.http
      .post<any>(apiEndpoint, data, {
        headers: this.headers,
        responseType: 'text' as 'json'
      })
      .pipe(map((data) => ({ message: data })))
  }

  /*
  * billing upsell
  * */
  submitUpsell<T>(pageId, rtClickId): Observable<any> {
    let apiEndpoint = `${environment.apiUrl}/billing/upsell`;
    let params = new HttpParams();
    params = params.append('pageId', pageId);
    params = params.append('rtClickId', rtClickId);
    return this.http
      .post<any>(apiEndpoint,  params )
      .pipe(catchError((error) => of({ error: error.message })));
  }
}
