<section class="challenge-variation challenge-variation-blue-texture-bg">
    <p class="challenge-variation-width-lg challenge-variation-text-50">The FREE 28-Day WeShape Feel Good Challenge Can Change Your Life!</p>
    <div class="challenge-variation-width-lg challenge-group">
        <div style="padding:24px 0;">
            <p class="challenge-variation-text-28">Most Fitness Challenges:</p>
            <ul class="challenge-variation-text-22 challenge-list-none">
                <li><span>👎</span> Push you too hard too fast, risking injury</li>
                <li><span>👎</span> Don't focus on teaching you good form</li>
                <li><span>👎</span> Make you feel bad about your body to motivate you</li>
            </ul>
        </div>
        <div class="challenge-variation-blue-bg">
            <p class="challenge-variation-text-28"><span>WeShape</span>'s Feel Good Challenge:</p>
            <ul class="challenge-variation-text-22 challenge-list-none">
                <li><span>✅</span> Personalizes EVERY workout to your goals and capabilities</li>
                <li><span>✅</span> Focuses on FORM FIRST through our Visual Teaching System</li>
                <li><span>✅</span> Uses Positive Psychology to boost self confidence & motivation</li>
            </ul>
        </div>
    </div>
    <div class="challenge-variation-width-xl px-3 px-md-0">
        <p class="challenge-variation-text-35">See What People are Saying...</p>
        <app-challenge-testimonials></app-challenge-testimonials>
    </div>
    <div class="challenge-variation-width-xl">
        <p class="challenge-variation-text-35">Here's What You Get For FREE:</p>
        <div class="challenge-variation-text-22 challenge-variation-content-4">
            <div>
                <span [inlineSVG]="'/assets/images/PersonSimpleRun.svg'"></span>
                <p>28 Days of Personalized Follow-Along Workouts</p>
            </div>
            <div>
                <span [inlineSVG]="'/assets/images/PersonSupport.svg'"></span>
                <p>Weekly LIVE Coaching & Motivation 
                </p>
            </div>
            <div>
                <span [inlineSVG]="'/assets/images/TreePalm.svg'"></span>
                <p>The Chance To Win AMAZING Prizes
                </p>
            </div>
            <div>
                <span [inlineSVG]="'/assets/images/Chart.svg'"></span>
                <p>Our 28-Day "4 Pillar" Curriculum That's PROVEN To Work
                </p>
            </div>
        </div>
    </div>

    <div #signupForm class="challenge-variation-content-signup challenge-variation-dark-blue-bg-opaque w-100 py-5">
        <p class="challenge-variation-text-35">Our Feel Good Challenge Starts: Monday, June 3rd, 2024</p>
        <p class="challenge-variation-text-50">Sign Up For Free Before You Miss Out!</p>
        <div elementInView (isVisible)="_isMobile && formVisible($event)" class="challenge-variation-width-md mx-auto">
            <div class="my-3">
                <app-timer [main_txt]="'REGISTRATION CLOSES IN...'" [main_theme_color]="'lighter-blue'" [corners]="'border-round'" [is_present]="'d-none'" [main]="'timer--block'" [remainingTime]="timer" [fullTimeName]="true" [numDigits]="'2'"></app-timer>
            </div>
            <div class="challenge-variation-dark-blue-bg">
                <p>Sign Up</p>
                <app-challenge-signup></app-challenge-signup>
            </div>
        </div>
    </div>
    <button #floatingButton *ngIf="_isMobile && showButton" class="challenge-btn floating-btn" type="button" (click)="scrollTo()">Sign Up</button>
</section>