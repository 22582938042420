import { Directive, Output, EventEmitter, ElementRef, Host, Input } from '@angular/core';
/**
 * https://stackoverflow.com/questions/64129620/angular-2-understand-if-dom-element-is-within-viewport
 */


/**
 * @description
 * Directive that users the IntersectionObserver to listen to when an element appears in viewport
 * 
 * @emits
 * Outputs isVisible true/false
 */
@Directive({
  selector: '[elementInView]'
})
export class ElementInViewDirective {
  @Input() elementOffset = '15px';
  @Output() isVisible = new EventEmitter<boolean>();
  private _observer: IntersectionObserver;

  constructor(@Host() private _elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const options = {root: null, rootMargin: this.elementOffset,threshold: 0.0};
    this._observer = new IntersectionObserver(this._callback, options);
    this._observer.observe(this._elementRef.nativeElement);
  }

  ngOnDestroy() {this._observer.disconnect();}

  private _callback = (entries, observer) => {
    entries.forEach(entry => 
        this.isVisible.emit(entry.isIntersecting ? true : false));
  };
}