<section class="challenge-variation challenge-variation-blue-texture-bg">
    <p class="challenge-variation-text-50">Join the Movement!</p>
    <p class="challenge-variation-text-35 challenge-variation-width-lg text-gray">Starting June 3rd - WeShape is hosting a FREE 4 week Feel Good Challenge, and you're invited to join us! </p>
    <p class="challenge-variation-text-35 challenge-flex-30">So how does the Feel Good Challenge work?</p>

    <div class="challenge-variation-width-xxl challenge-list-flex-30">
        <div class="challenge-list-item">
            <div>1</div>
            <div>
                <p class="challenge-variation-text-25">Fill out the form</p>
                <p>Join the Challenge for FREE!</p>
            </div>
        </div>
        <div class="challenge-list-item">
            <div class="text-gray">2</div>
            <div>
                <p class="challenge-variation-text-25">Download our app</p>
                <p>Access your account and customize your workout profile!</p>
            </div>
        </div>
        <div class="challenge-list-item">
            <div class="text-gray">3</div>
            <div>
                <p class="challenge-variation-text-25">Participate in mini challenges</p>
                <p>Build healthy habits and earn points for a chance at our grand prize!</p>
            </div>
        </div>
        <div class="challenge-list-item">
            <div class="text-gray">4</div>
            <div>
                <p class="challenge-variation-text-25">Join live Zoom sessions</p>
                <p>Get support & guidance, and say hello to our Community!</p>
            </div>
        </div>
        <div class="challenge-list-item">
            <div class="text-gray">5</div>
            <div>
                <p class="challenge-variation-text-25">Finish the challenge with new tools and a community</p>
                <p>By the end, you'll have the tools to feel good in your body and reach your goals!</p>
            </div>
        </div>
    </div>
    <button *ngIf="_isMobile" class="challenge-btn" type="button" (click)="scrollTo()">Sign Up</button>

    <div class="challenge-variation-dark-blue-bg">
        <p class="challenge-variation-text-28">If you're looking for...</p>
        <ul class="challenge-list-none">
            <li>✅ Something that will jumpstart your health and fitness journey</li>
            <li>✅ A four-week program that will boost your motivation and self-confidence</li>
            <li>✅ Access to workouts, guidance and support with NO CREDIT CARD REQUIRED</li>
        </ul>
    </div>
    <p class="challenge-variation-width-lg challenge-variation-text-35">Then make sure to sign up below to participate in our next <br> Feel Good Challenge!</p>
    <div #signupForm class="challenge-variation-content-signup challenge-variation-dark-blue-bg-opaque w-100 py-5">
        <p class="challenge-variation-text-35">Our Feel Good Challenge Starts: Monday, June 3rd, 2024</p>
        <p class="challenge-variation-text-50">Sign Up For Free Before You Miss Out!</p>
        <div class="challenge-variation-width-md mx-auto">
            <div class="my-3">
                <app-timer [main_txt]="'REGISTRATION CLOSES IN...'" [main_theme_color]="'lighter-blue'" [corners]="'border-round'" [is_present]="'d-none'" [main]="'timer--block'" [remainingTime]="timer" [fullTimeName]="true" [numDigits]="'2'"></app-timer>
            </div>
            <div class="challenge-variation-dark-blue-bg">
                <p>Sign Up</p>
                <app-challenge-signup></app-challenge-signup>
            </div>
        </div>
    </div>

</section>