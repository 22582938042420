import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BillingService } from 'src/app/services';
declare const fbq:Function;

@Component({
  selector: 'app-checkout-chips',
  templateUrl: './checkout-chips.component.html',
  styleUrls: ['./checkout-chips.component.scss']
})
export class CheckoutChipsComponent implements OnInit {

  @Input() pageType: string;
  @Input() planOptions;
  @Input() selectedIndex;
  @Output() planSelected: any = new EventEmitter;
  @Input() cssClasses;

  public hidePromoCheckout:boolean = false;

  constructor(
    private billingService: BillingService
  ) { }

  @ViewChild("checkoutContainer") checkoutContainer: ElementRef;

  ngOnInit(): void {
    if (this.planOptions.promoType == "standalone") {
      this.selectedIndex = 0;
      this.hidePromoCheckout = true;
    }
    if(this.selectedIndex >= 0) {
      // User landed on page with their chosen plan.  Set that plan to the active
      this.onClickFreeTrial(this.planOptions.productItem[this.selectedIndex], this.selectedIndex)
    }
  }

  ngAfterViewInit(): void {
    this.scrollIntoView(this.checkoutContainer);
  }

  onClickFreeTrial(selectedPlan, index) {
    fbq('track', 'InitiateCheckout');
    if (this.pageType === 'promo') {
      this.hidePromoCheckout = true;
    }
    this.planOptions.productItem.forEach((input, arr) => {
      input.active = '';
      if(index === arr) {
        input.active = 'active';
        let planPrice =
          selectedPlan.hasOwnProperty('chargeNow')
            ? selectedPlan.chargeNow
            : selectedPlan.price;

        let data = {
          plan: selectedPlan.productId,
          price: planPrice,
          description: `Weshape ${selectedPlan.plan} Plan`,
          promoCode: '',
          legal: selectedPlan.legal,
        }

        this.billingService.productInformation = data;
        this.billingService.productInfoBS.next(data);
      }
    });
    this.planSelected.emit({ selectedPlan, index });
  }

  scrollIntoView(container) {
    container.nativeElement.scrollIntoView({ behavior: 'smooth'});
  }

}
