<app-header-full></app-header-full>
<section class="authentication verify">
  <div class="authentication-container">
    <div class="img-wrap">
      <img class="email-icon" src="assets/images/verify.png"
           alt="verify email icon">
    </div>
    <header class="verify">Welcome to WeShape</header>
    <p></p>
    <p class="main-txt"> To complete the registration, look for the verification link we sent to your email inbox. If email doesn't appear within few minutes, please check the spam folder. If there
      are questions or concerns, please reach out to support by calling 1-844-990-2456!</p>
    <p class="main-txt" *ngIf="coachingCall">
      If you would like to schedule your first included Care Call at this time, please click <a href="https://calendly.com/wecare-weshape/20min" target="_blank">here</a> to do so. Or, if you would prefer to schedule at a later date, a link to do so will be sent to your email inbox.
    </p>
  </div>
</section>
<app-footer></app-footer>
