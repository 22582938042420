import { Injectable } from '@angular/core';
import posthog from 'posthog-js'
import { SessionService } from './index';

@Injectable({
  providedIn: 'root'
})
export class PosthogService {
  initialized: boolean = false;
  bootstrapCookie: string = 'ph_sid';
  posthogCookie: string = 'ph_%KEY%_posthog';

  sessionDistinctID: string; // User's current distinct_id used for session
  initOptions = {
    autocapture: false,
    // capture_pageview: false,
    capture_pageleave: false,
    enable_recording_console_log: true,
  }
  userIdentified: boolean = false;
  posthogInstance = posthog; 

  constructor(
    private sessionService: SessionService
  ) { }

  initializePostHog(apiHost, key) {
    this.initOptions["api_host"] = apiHost;

    // Check if there are posthog bootstrap cookie or utm_id to append to the PostHog initialization
    let bootstrapCookie: any = this.sessionService.getCookie(this.bootstrapCookie) || undefined;


    // Check if a posthog cookie already been initialized (handle marketing lander pages)
    this.posthogCookie = this.posthogCookie.replace("%KEY%", key);
    let posthogCookie: any = this.sessionService.getCookie(this.posthogCookie) || undefined;

    if(posthogCookie)
      posthogCookie = JSON.parse(posthogCookie)?.distinct_id || undefined;

    let utmID: any = localStorage.getItem("utm_id") || undefined;
    let weshapeToken: any = localStorage.getItem("weshape_token") || undefined;

    // Store posthog cookie into appropriate variable to be removed with other user items
    if (bootstrapCookie) {
      this.sessionService.userCookieItems.push(this.bootstrapCookie);
    }

    // Returns first instance of valid token - ordered by importance
    let sessionToken = posthogCookie || weshapeToken || bootstrapCookie || utmID;

    if (sessionToken) {
      this.setDistinctID(sessionToken);
      this.initOptions["bootstrap"] = {
        distinctID: sessionToken
      };
      this.posthogInstance.init(key, this.initOptions);
      this.initialized = true;
    }
  }

    /**
   * Associate session to main posthog identifier (i.e. weshape token)
   * 
   * @param token Distinct id used for alias
   * @param options User properties
   */
  identifyUser(token, options = null) {
    // Prevents multiple identify calls
    if (this.userIdentified) {
      return;
    }

    // Calls posthog service to identify user once main distinct_id is determined
    if (options) {
      this.posthogInstance.identify(token, options)
    } else {
      this.posthogInstance.identify(token);
    }
    this.userIdentified = true;
    this.aliasUser(token);
  }

  /**
   * Set the distinct id used to the main distinct_id called in posthog's identify method
   * 
   * @param token Distinct id used for alias
   */
  aliasUser(token) {
    if (!this.sessionDistinctID) {
      this.sessionDistinctID = token;
      return;
    } else if (this.sessionDistinctID === token) {
      return
    }

    this.posthogInstance.alias(this.sessionDistinctID, token);

    // Set sessionDistinctID to updated main id
    this.sessionDistinctID = token;
  }

    /**
   * PostHog event capture
   * @param event Event Name
   * @param eventProps Event Specific Properties
   * @param userProps User Specific Properties.  Set with PostHog $set method
   */
  capture(event, eventProps = null, userProps = null) {
    // User Properties exists, format eventProperties to include user properties
    let properties =
      userProps && eventProps
        ? this.setUserEventProperties(eventProps, userProps)
        : eventProps;

    if (eventProps) {
      this.posthogInstance.capture(event, properties);
    } else {
      this.posthogInstance.capture(event);
    }
  }

  /**
   * Resets Posthog instance, including their device id
   * Prevents cases where a device can be shared between multiple people
   */
  reset() {
    this.sessionService.removeCookie(this.bootstrapCookie)
    this.posthogInstance.reset(true);
  }

  /**
   * User's latest feature flag values
   */
  reloadFeatureFlags() {
    this.posthogInstance.reloadFeatureFlags()
  }

  /**
   * Feature Flag Boolean Value
   * @param key feature flag key
   * @returns Boolean true/false
  */
  isFeatureEnabled(key) {
    return this.posthogInstance.isFeatureEnabled(key)
  }

  /**
   * Feature Flag Value
   * @param key feature flag key
   * @returns multivariate value - i.e. 'control', 'v16', 'v17', etc
   */
  getFeatureFlag(key) {
    return this.posthogInstance.getFeatureFlag(key);
  }

  /**
   * Feature Flag Payload
   * @param key - Feature Flag Key
   * @returns Payload set in posthog
   */
  getFeatureFlagPayload(key) {
    return this.posthogInstance.getFeatureFlagPayload(key)
  }

  setDistinctID(value) {
    // Session's distinct id that will be sent in with events
    this.sessionDistinctID = value;
  }

  setUserEventProperties(eventProps, userProps) {
    eventProps['$set'] = userProps;
    return eventProps;
  }
}
