import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  coachingCall: boolean = false;

  constructor(
    private sessionService: SessionService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Email Verified | Weshape');

    if (history?.state) {
      this.coachingCall = history?.state?.coachingCall;
    }

    // Remove all stored user's information.  User has completed entire purchase flow
    this.sessionService.removeUserItems();
  }

}
