import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-full',
  templateUrl: './header-full.component.html',
  styleUrls: ['./header-full.component.scss']
})
export class HeaderFullComponent implements OnInit {
  currentUrl = '' as string;
  navContext = '' as string;

  constructor(private router: Router, private sessionService: SessionService) { }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.navContext = this.sessionService.navContext;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  routeToPage(navLink: any) {
    this.router.navigateByUrl(this.navContext + navLink);
  }

  getWWWUrl(uri){
    return environment.wwwUrl + '/' + uri;
  }

}
