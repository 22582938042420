<app-header-min></app-header-min>
<section class="demo demo-container">
    <section class="demo-video">
        <header (click)="showInformation()">{{ userInfo.profile.firstName }}'s Workout</header>
        <div class="video-player">
            <app-video-player videoID="workout-demo-player" [userInfo]="userInfo" [workoutDemoItem]="workoutDemoItem" [movementList]="movementList" (sendTag)="sendTag($event)" (videoCompletion)="videoCompletion($event)"></app-video-player>
        </div>
        <button class="btn-sign-up" (click)="showInformation()">Get Started</button>
    </section>
    <section #salesInformation class="demo-information">
        <div class="mb-5">
            <div class="white-container-black-txt">
                As a THANK YOU for taking the time to try your first Customized Follow Along Workout, <u><b>we wanted to reward you</b></u> with a 14 Day WeShape Membership for FREE!
            </div>
            <div class="text-price">
                Todays Price = $0.00
            </div>
            <div class="black-mid-txt">
                <p><u>You'll have 2 full weeks</u> to try the <b>Customized Follow Along Workouts</b> we created for you! To continue, all you have to do is select the plan you'd like to try out.</p>
                <p> And don't worry if you don't like it. <u>You can cancel any time during the 14 day trial</u> by calling or emailing our
                    US based customer success coaches and <b>YOU WILL NOT BE CHARGED ANYTHING</b>!</p>
            </div>
            <div class="txt-which-plan">
                Which plan would you like to try out, {{ userInfo.profile.firstName }}?
            </div>
        </div>
        <app-checkout-chips [pageType]="pageType" [planOptions]="planOptions" [selectedIndex]="billingService.selectedIndex" (planSelected)="billingService.planSelected = $event" ></app-checkout-chips>
        <app-registration-form *ngIf="newUser && billingService.planSelected" (updateUser)="billingService.showOptions = true"></app-registration-form>
        <app-payment *ngIf="billingService.planSelected" [planOptions]="planOptions" [pageType]="pageType" [newUser]="newUser" [pageId]="pageId"></app-payment>
    </section>
</section>
<app-footer-min></app-footer-min>
