import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

import { BaseService } from './base.service';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SessionService extends BaseService {

  navContext = '' as string;
  sessionParams: Map<string, string> = new Map<string, string>();
  userQuizResponse;
  quizVersionSlug:string;
  quizItemKey = 'weshape-survey';
  weshapeToken;
  quizQuestionsToSkip: string[];

  referralInfo = {
    referral_code: '',
    referral_rule: ''
  }

  userLocalStorageItems = ['weshape_token', 'wid_token', 'utm_id', 'weshape-survey'];
  userSessionStorageItems = ['rtkclickid'];
  userCookieItems = ['utm_id'];

  paypalParams = ["paymentState", "paymentType"];

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private _document: Document,
    private cookieService: CookieService
  ) {
    super();
  }
  window = this._document.defaultView;


  setNavContext(path: string) {
    this.navContext = path;
  }

  addSessionParamater(key: string, value: string) {
    this.sessionParams.set(key, value);
  }

  getSessionParamater(key: string) {
    return this.sessionParams.get(key);
  }

  setReferralInfo(key, value) {
    window.localStorage.setItem(key, value);
  }

  getReferralInfo(key) {
    return window.localStorage.getItem(key);
  }

  setUserQuizResponse(details) {
    this.userQuizResponse = details;
    if(details && details.response){
      window.localStorage.setItem(this.quizItemKey, JSON.stringify(details.response));
      this.setQuizVersionSlug(this.userQuizResponse?.quizVersionSlug);
    }
  }

  getUserQuizResponse() {
    return this.userQuizResponse;
  }

  setQuizQuestionsToSkip(names:string[]){
    this.quizQuestionsToSkip = names;
  }

  getQuizQuestionsToSkip():string[]{
    return this.quizQuestionsToSkip;
  }

  setQuizVersionSlug(slug)
  {
    this.quizVersionSlug = slug;
  }

  getQuizVersionSlug(){
    return this.quizVersionSlug;
  }

  cleanUserQuizResponse(){
    this.userQuizResponse = null;
    this.window.localStorage.removeItem(this.quizItemKey);
  }

  getWeshapeToken(){
    return this.userQuizResponse?this.userQuizResponse.weshapeToken:this.weshapeToken;
  }

  getCookie(key) {
    return this.cookieService.get(key);
  }

  setCookie(key, value, expiryInMin=60) {
    let expiryDate = new Date();
    expiryDate.setMinutes(expiryDate.getMinutes() + expiryInMin);

    this.cookieService.set(key, value, { path: "/", expires: expiryDate, domain: "weshape.com" });
  }

  removeCookie(key) {
    this.cookieService.delete(key, "/", ".weshape.com");
  }

  removeUserItems() {
    this.userLocalStorageItems.forEach(key => localStorage.removeItem(key));
    this.userSessionStorageItems.forEach(key => sessionStorage.removeItem(key));
    this.userCookieItems.forEach(key => this.removeCookie(key));
  }

  addTagManagerEvent(event: any) {
    this.window.dataLayer.push(event);
  }

  hasIgnoredParams(params, ignoredParams: string[]) {
    return ignoredParams.some( item => item in params );
  }
}
