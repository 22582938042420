<ng-container *ngIf="version === 'timer-hero'">
    <div class="timer timer-hero">
        <div class="timer-group timer-group-left">
            <div class="tagline">{{ tagline }}</div>
            <div #timer class="time-left"></div>
        </div>
        <div class="timer-group timer-group-right">
            <div class="basic-button" (click)="ctaClicked()">{{ buttonText }}</div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="version === 'timer-personalize'">
    <div class="timer timer-personalize">
        <div class="tagline"> {{ tagline }} </div>
        <div #timer class="time-left"></div>
        <div>min</div>
    </div>
</ng-container>