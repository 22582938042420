<div id="upsellCheckout" class="upsell">
  <div class="form-group form-check upsell-checkout">
    <input type="checkbox" class="form-check-input checkout-tick" [checked]="checkbox1" (change)="checkbox1 = !checkbox1"/>
    <label class="form-check-label upsell-checkout-terms" [innerHTML]="planOptions.legal"></label>
  </div>
  <div matTooltip="You cannot delete that"
       matTooltip="Please accept the terms and conditions above to continue."
       matTooltipPosition="below"
       matTooltipShowDelay="100"
       [matTooltipDisabled]="checkbox1">
    <button (click)="upgradeTo()" mat-raised-button [disabled]="!checkbox1" class="upsell-checkout-upgrade">
      {{planOptions.upsellButtonText}}
    </button>
  </div>
  <p class="upsell-checkout-no" (click)="routeToVerify('checkout-no')">No Thanks. I’ll stick with my free trial</p>
</div>

