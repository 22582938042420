import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-challenge-v009',
  templateUrl: './challenge-v009.component.html',
  styleUrls: ['./challenge-v009.component.scss']
})
export class ChallengeV009Component implements OnInit {
  @Input() timer;
  @Input() set isMobile(value: boolean) {
    this._isMobile = value;
  }
  _isMobile: boolean = false;

  @ViewChild('signupForm') signupForm?: ElementRef;
  constructor() { }

  ngOnInit(): void {
  }
  scrollTo() {
    this.signupForm.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
