import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MaropostService {
  apiUrl = environment.apiUrl;
  headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );

  constructor(
    private http: HttpClient
  ) { }

  addTag(data): Observable<any> {
    let apiEndpoint = `${this.apiUrl}/quiz/add-tag`;
    return this.http.post<{tagId: string}>(apiEndpoint, data, {
      headers: this.headers,
      responseType: 'text' as 'json'
    }).pipe(
      map(res => ({ message: res }))
    );
  }
}
