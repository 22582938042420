import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
  SimpleChanges
} from '@angular/core';

/* Swiper Elements */
import { SwiperOptions, Swiper } from "swiper";
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-video-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent implements OnInit {
  @Input() playlist = [];
  @Input() showPlaylist: boolean = false;
  @Output() playlistIndex = new EventEmitter();

  /* Playlist */
  nextVideo: number = 1;
  currentActivePlaylist: number;
  swiperConfig: SwiperOptions;

  @ViewChild('swiperContainer') playlistSwiperContainer: ElementRef;
  @ViewChild('swiperEl') swiperEl: ElementRef;

  swiperInstance;

  constructor() { }

  ngOnInit(): void { }
  ngAfterViewInit(): void {
    register();
  }

  doSetSwiperConfig() {
    //Set Swiper Config for playlist
    this.swiperConfig = {
      observer: true,
      observeParents: true,
      centeredSlides: true,
      loop: false,
      pagination: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      allowTouchMove: true,
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        568: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 20
        },
        1440: {
          slidesPerView: 5,
          spaceBetween: 25
        }
      }
    };
    Object.assign(this.swiperEl.nativeElement, this.swiperConfig);
    this.swiperEl.nativeElement.initialize();
    this.swiperInstance = this.swiperEl.nativeElement.swiper;
  }

  doSwitchVideo(playlistIndex, swiperIndex) {
    // Set the swiper slide to the center and active item
    this.swiperInstance.slideTo(swiperIndex, 100, false);

    // emit the playlistIndex for updating
    this.playlistIndex.emit(playlistIndex);
    this.doTogglePlaylist();
  }

  doTogglePlaylist() {
    // Update Swiper Playlist to handle any width changes, etc
    this.playlistSwiperContainer.nativeElement.classList.toggle('active');
    this.swiperInstance.update();
  }

  onActiveIndexChange() {
    console.log(this.swiperInstance.activeIndex);
  }
}