<div class="carousel">
    <swiper-container #swiperEl class="swiper-container" init="false">
        <swiper-slide *ngFor="let item of slides; index as swiperIndex">
            <div class="swiper-container-slide">
                <div class="slides-txt">
                    <p>{{ item.quote }}</p>
                    <span>{{item.name }}</span>
                    <span class="background-icon" [inlineSVG]="'/assets/images/Quote.svg'"></span>
                </div>
            </div>
        </swiper-slide>
    </swiper-container>

    <div slot="container-end">
        <div class="swiper-pagination"></div>
    </div>
</div>