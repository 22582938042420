import { Component, OnInit, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-tabs-accordion',
  templateUrl: './tabs-accordion.component.html',
  styleUrls: ['./tabs-accordion.component.scss']
})
export class TabsAccordionComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  @Input() contentArr: any;
  activeTabIndex: number = 0;
  innerWidth = 0 as number;
  constructor() { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  selectPill(index: number) {
    this.activeTabIndex = index;
  }

}
