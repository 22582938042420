import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feel-better-guarantee',
  templateUrl: './feel-better-guarantee.component.html',
  styleUrls: ['./feel-better-guarantee.component.scss']
})
export class FeelBetterGuaranteeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
