<section class="challenge-variation challenge-variation-blue-texture-bg">
  <div class="row gx-5 justify-content-center">
    <div class="col-12 col-lg-10">
      <div class="challenge-variation-timer-width">
        <app-timer [main_txt]="'REGISTRATION CLOSES IN...'"
        [main_theme_color]="'lighter-blue'"
        [corners]="'border-round'"
        [is_present]="'d-none'"
        [main]="'timer--block'"
        [remainingTime]="timer"
        [fullTimeName]="true"
        [numDigits]="'2'"
        ></app-timer>
      </div>
      <div class="challenge-variation-content">
        <div class="challenge-variation-content-text">
          <p class="challenge-variation-text-50">The WeShape Feel Good Challenge! 4 Weeks Of:</p>
          <div class="challenge-variation-content-text-list">
            <p><span [inlineSVG]="'/assets/images/green-right-caret.svg'"></span>Personalized Workouts</p>
            <p><span [inlineSVG]="'/assets/images/green-right-caret.svg'"></span>LIVE Coaching</p>
            <p><span [inlineSVG]="'/assets/images/green-right-caret.svg'"></span>Supportive Community</p>
          </div>
        </div>
        <div class="challenge-variation-content-signup challenge-variation-content-signup-bgc">
          <p class="challenge-variation-text-28">Sign up 100% FREE below...</p>
         <app-challenge-signup></app-challenge-signup>
        </div>
      </div>
    </div>
  </div>
</section>
