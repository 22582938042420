import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { SessionService, SurveyService, PosthogService } from '../services';
import posthog from 'posthog-js'

@Injectable({
  providedIn: 'root'
})
export class SalesWeshapeTokenUserPageResolver implements Resolve<boolean> {
  constructor(
    private quizService: SurveyService,
    private sessionService: SessionService,
    private router: Router,
    private posthogService: PosthogService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    /**
     * Return user data based on weshape token
    */

    let weshapeToken = localStorage.getItem("weshape_token");

    // Check to see if the query params contain paypal items.  If so, do not replace the weshape token
    let ignoreTokenReplace = this.sessionService.hasIgnoredParams(route.queryParams, this.sessionService.paypalParams);

    if ((route.queryParams && route.queryParams.token) && !ignoreTokenReplace) {
      weshapeToken = route.queryParams.token;
      // localStorage.setItem("weshape_token", weshapeToken.trim());
    }

    if (!weshapeToken) {
      this.router.navigateByUrl('/quiz');
      return of(null);
    }

    // this.sessionService.weshapeToken = weshapeToken;

    return this.quizService.getResponseWithToken(weshapeToken).pipe(
      tap(res => {
        // Set the user's unique session token into storage to use for calls
        if (res && res.profile)
          localStorage.setItem('wid_token', res.profile.id);

        // Valid Weshape Token
        localStorage.setItem("weshape_token", weshapeToken.trim());
        this.sessionService.weshapeToken = weshapeToken;

        // Set the user's session with user's distinct id (weshape-token) for tracking
        this.posthogService.identifyUser(res.weshapeToken, {
          name: `${res.response?.names?.first_name} ${res.response?.names?.last_name}`,
          email: res.email
        })
        this.sessionService.setUserQuizResponse(res);

        if (!res) {
          this.router.navigate(['/']);
        }
        else if (res && !res.quizComplete) {
          this.router.navigateByUrl('/quiz');
        }
      }),
      catchError(error => {
        console.error(`Can't resolve user with token ${weshapeToken} because of the error:`, error);
        // console.error();

        // Issue with the weshape token, remove any local storage items related to user
        this.sessionService.removeUserItems();

        return of(null);
      }));
  }
}
