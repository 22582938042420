import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, map, tap } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SessionService } from "./session.service";

@Injectable({
  providedIn: 'root'
})
export class UTMService {

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private sessionService: SessionService
  ) {
    // Check to see if user has a utm_id in a cookie set by an external lander
    let utm_id = this.checkUtmCookie();
    if (utm_id)
      localStorage.setItem('utm_id', utm_id);
  }

  deviceInfo = null;


  /** Endpoints */
  saveUTM<T>(data): Observable<any> {
    let apiEndpoint = `${environment.apiUrl}/saveutm`;
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post<any>(apiEndpoint, data);
  }

  updateUTM<T>(data): Observable<any> {
    let apiEndpoint = `${environment.apiUrl}/updateutm`;
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post<any>(apiEndpoint, data, {
        headers,
        responseType: 'text' as 'json'
      })
      .pipe(map((data) => ({ message: data })));
  }


  /** Methods */
  initUTMItems() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    let obj = Object.fromEntries(this.urlToObject());
    return this.saveUTM(obj);
  }

  checkUtmCookie() {
   let utmCookie = this.sessionService.getCookie("utm_id") || undefined;
   return utmCookie;
  }

  urlToObject() {
    const result: Map<string, string> = new Map();
    let searchParams = new URLSearchParams(window.location.search);
    for (const [key, value] of searchParams) {
      result.set(key, value);
    }

    Object.keys(this.deviceInfo).forEach(element => {
      var key = element;
      if(key && key == 'userAgent')
        key = 'user_agent';
      else if(key && key == 'deviceType')
        key = 'device_type';
      result.set(key, this.deviceInfo[element]);
    });

    Object.keys(this.sessionService.referralInfo).forEach(element => {
      if(result.get(element))
        this.sessionService.setReferralInfo(element, result.get(element));
    });

    if(result.has("nourl"))
      result.delete("nourl");
    else
      result.set('url', String(window.location.pathname));
      
    result.set('is_mobile', String(this.deviceService.isMobile()));
    result.set('is_tablet', String(this.deviceService.isTablet()));
    result.set('is_desktop', String(this.deviceService.isDesktop()));
    return result;
  }


}
