import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { SessionService, SurveyService } from '../services';

@Injectable({
  providedIn: 'root'
})

export class FairyPostSalesPageResolver implements Resolve<boolean> {
  constructor(
    private quizService: SurveyService,
    private sessionService: SessionService,
    private router: Router,
  ) { }

  resolve(): Observable<boolean> {

    //Check if user has quiz in local storage
    let quizResponse = localStorage.getItem(this.sessionService.quizItemKey);
    let getUserQuizResponse = this.sessionService.getUserQuizResponse();

    if(getUserQuizResponse) {
      return of(getUserQuizResponse);
    }

    if(quizResponse) {
      return of(true);
    }

    return this.quizService.getFairyQuizVersion().pipe(
      tap(res => {
        // Set the user's unique session token into storage to use for calls
        if (res)
          this.sessionService.setQuizVersionSlug(res.slug);

        this.router.navigateByUrl('/fairy-quiz');
      }),
      catchError(error => {
        console.error(`Can't resolve user with fairy funnel quiz because of the error:`, error);
        // console.error();
        return of(null);
      }));
  }
}
