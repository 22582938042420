import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-min',
  templateUrl: './header-min.component.html',
  styleUrls: ['./header-min.component.scss']
})
export class HeaderMinComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getWWWUrl(uri){
    return environment.wwwUrl + '/' + uri;
  }

}
