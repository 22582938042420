<section class="challenge-variation challenge-variation-blue-texture-bg">
  <div class="row gx-5 justify-content-center">
    <div class="col-12 col-md-8">
      <p class="challenge-variation-text-50">The FREE WeShape Feel Good Challenge!</p>
      <p class="challenge-variation-text-28">Sign up 100% FREE below...</p>
      <div class="my-4">
        <app-timer [main_txt]="'REGISTRATION CLOSES IN...'"
        [main_theme_color]="'lighter-blue'"
        [corners]="'border-round'"
        [is_present]="'d-none'"
        [main]="'timer--block'"
        [remainingTime]="timer"
        [fullTimeName]="true"
        [numDigits]="'2'"
        ></app-timer>
      </div>
      <app-challenge-signup></app-challenge-signup>
    </div>
  </div>
</section>
