<section class="challenge-variation challenge-variation-blue-texture-bg">
    <p class="challenge-variation-text-50 challenge-variation-width-lg">Do you want to gain strength, flexibility & self-confidence in JUST 28-DAYS?</p>
    <p class="challenge-variation-text-35 challenge-variation-width-lg">Get 100% <u>FREE ACCESS</u> to The WeShape Feel Good Challenge!</p>
    <div class="challenge-variation-blue-bg">
        <p class="challenge-variation-text-28">Here's What You Get For FREE:</p>
        <ul class="challenge-variation-text-22 challenge-variation-width-md challenge-list-none">
            <li><span>✅</span> 28 Days Of Personalized Follow Along Workouts</li>
            <li><span>✅</span> Weekly LIVE Coaching & Motivation</li>
            <li><span>✅</span> Our 28 Day “4 Pillar” Curriculum That's PROVEN To Work</li>
            <li><span>✅</span> The Chance To Win AMAZING Prizes</li>
        </ul>
    </div>
    <div class="challenge-variation-width-xl px-3 px-md-0">
        <p class="challenge-variation-text-35">See What People are Saying...</p>
        <app-challenge-testimonials></app-challenge-testimonials>
    </div>
    <div class="challenge-variation-width-xl">
        <p class="challenge-variation-text-35">What Makes Us Different?</p>
        <div class="challenge-variation-content-4">
            <div>
                <span [inlineSVG]="'/assets/images/CurrencyDollarSimple.svg'"></span>
                <p class="challenge-variation-text-22">It's 100% FREE (No Credit Cards Whatsoever)</p>
            </div>
            <div>
                <span [inlineSVG]="'/assets/images/StarFour.svg'"></span>
                <p class="challenge-variation-text-22">It's BETTER than most programs that cost $100+</p>
            </div>
            <div>
                <span [inlineSVG]="'/assets/images/Chart.svg'"></span>
                <p class="challenge-variation-text-22">It's sustainable and most people who complete the challenge keep going</p>
            </div>
            <div>
                <span [inlineSVG]="'/assets/images/PersonSimpleWalk.svg'"></span>
                <p class="challenge-variation-text-22">It's PERFECT for complete beginners and people with movement restrictions</p>
            </div>
        </div>
    </div>
    <div #signupForm class="challenge-variation-content-signup challenge-variation-dark-blue-bg-opaque w-100 py-5">
        <p class="challenge-variation-text-35">Our Feel Good Challenge Starts: Monday, September 23rd, 2024</p>
        <p class="challenge-variation-text-50">Sign Up For Free Before You Miss Out!</p>
        <div elementInView (isVisible)="_isMobile && formVisible($event)" class="challenge-variation-width-md mx-auto">
            <div class="my-3">
                <app-timer [main_txt]="'REGISTRATION CLOSES IN...'" [main_theme_color]="'lighter-blue'" [corners]="'border-round'" [is_present]="'d-none'" [main]="'timer--block'" [remainingTime]="timer" [fullTimeName]="true" [numDigits]="'2'"></app-timer>

            </div>
            <div class="challenge-variation-dark-blue-bg">
                <p>Sign Up</p>
                <app-challenge-signup></app-challenge-signup>
            </div>

        </div>
    </div>

    <button #floatingButton *ngIf="_isMobile && showButton" class="challenge-btn floating-btn" type="button" (click)="scrollTo()">Sign Up</button>
</section>
