import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService, SessionService } from 'src/app/services';
import { timer } from 'rxjs'

@Component({
  selector: 'app-quiz-bypass-pages',
  template: ``,
  styles: ['']
})
export class QuizBypassPagesComponent implements OnInit {

  constructor(public route: ActivatedRoute,
    private sessionService: SessionService,
    private eventService: EventService,
    private router: Router) { }

  ngOnInit(): void {
    if (this.route.snapshot.url) {
      const segments = this.route.snapshot.url.map(x => x.path);
      const params = this.route.snapshot.queryParams;

      if (params.survey) {
        //{"response": {  "goal": "fbb",   "birthday": "1972-01-01",  "pageNo": 1 } }
        //https://devlelopment.weshape.com/quizpage?survey=eyJyZXNwb25zZSI6IHsgICJnb2FsIjogImZiYiIsICAgImJpcnRoZGF5IjogIjE5NzItMDEtMDEiLCAgInBhZ2VObyI6IDEgfSB9
        const json = JSON.parse(atob(params.survey));
        this.sessionService.setUserQuizResponse(json);
        var questionsToSKip = [];
        for (var x in json.response) {
          if (x !== 'curentPageName' && x !== 'pageNo')
            questionsToSKip.push(x);
        }
        this.sessionService.setQuizQuestionsToSkip(questionsToSKip);
      }
      else if (segments) {
        if (segments.length == 2) {
          ///quizpage/fby
          const goal = segments[1];
          var json = { "response": { "goal": goal, "pageNo": 2, "curentPageName": "challenge" } };
          if (goal == 'goal')
            json = { "response": { "goal": '', "pageNo": 1, "curentPageName": "goal" } };
          this.sessionService.setUserQuizResponse(json);
        }
        else if (segments.length == 3) {
          const seg1 = segments[1];
          const seg2 = segments[2];
          //this is for making exisintg used links work
          ///quizpage/fby/ycst
          if (seg1.toLowerCase() === "fby" || seg1.toLowerCase() === "fbb") {
            const goal = seg1;
            const challenge = seg2;
            const json = { "response": { "goal": goal, ['challenge-' + goal]: challenge, "challenge": challenge, "pageNo": 3, "curentPageName": "time_attempted" } };
            this.sessionService.setUserQuizResponse(json);
          }
          //generic condition to skip quiz question
          ///quizpage/gender/m
          else {
            var responseJson = {};
            responseJson[seg1] = seg2;
            this.sessionService.setUserQuizResponse({ "response": responseJson });
            this.sessionService.setQuizQuestionsToSkip([seg1]);
          }
        }
      }

      if (params.version) {
        // set quiz version
        // quizpage/gender/m?version=v14 or quizpage?version=v14&survey=...
        this.sessionService.setQuizVersionSlug(params.version);
      }




      // Allow 3 seconds for redtrack to create required cookie with clickID
      timer(3000).subscribe(() => {
        // Assuming any quiz bypass is a quiz start
        this.eventService.sendEvent({
          event: 'quiz started',
          event_properties: { 'quiz_version': params.version }
        });
        this.eventService.sendRedtrackEvent('quiz-starts');
      })


      if (params.quiztype && params.quiztype == 'fqz') {
        this.router.navigate(['/fairy-quiz']);
      } else
        this.router.navigate(['/quiz']);
    }
  }

}
