<section class="challenge-variation challenge-variation-page-bg-dark-blue">
  <img class="challenge-variation-banner-image" src="/assets/images/challenge-optin/challenge-banner.svg" alt="challenge banner">
  <p class="challenge-variation-text-28">Here's how it works: </p>
  <div>
    <ol class="list-circles challenge-variation-width-md">
      <li class="challenge-variation-text-22">Fill out the form below to get signed up for our free 28 day Challenge</li>
      <li class="challenge-variation-text-22">Log in and access your personalized WeShape account & Challenge Dashboard</li>
      <li class="challenge-variation-text-22">Join the fun as we guide you through our 4 week program that is PROVEN to help you feel amazing in your body & about your body</li>
    </ol>
  </div>

  <div class="challenge-variation-blue-bg challenge-variation-width-full">
    <p class="challenge-variation-text-28">When you join today, you’ll get FREE access to: </p>
    <div class="challenge-variation-content-2 challenge-variation-width-md">
      <div>
          <p class="challenge-variation-text-22 challenge-variation-list-border">28 Days of Customized Workouts</p>
      </div>
      <div>
          <p class="challenge-variation-text-22 challenge-variation-list-border">Community, Connection & Support</p>
      </div>
      <div>
          <p class="challenge-variation-text-22 challenge-variation-list-border">Weekly LIVE Coaching Sessions</p>
      </div>
      <div>
          <p class="challenge-variation-text-22 challenge-variation-list-border">Chance to win AMAZING prizes!</p>
      </div>
    </div>
  </div>
  <div class="challenge-variation-width-xl">
    <p class="challenge-variation-text-35 py-3">✨ And the best news is, there’s NO CREDIT CARD REQUIRED! ✨</p>
    <div class="challenge-variation-blue-bg challenge-variation-width-lg">
      <p class="challenge-variation-text-35">In this Challenge, we not only tackle your physical health, but also your mentality!</p>
      <p>
        <span>⭐</span>
        <span class="challenge-variation-text-22">⭐</span>
        <span class="challenge-variation-text-35">⭐</span>
        <span class="challenge-variation-text-22">⭐</span>
        <span>⭐</span>
       </p>
      <p class="challenge-variation-text-35">And for so many, this approach has been a </p>
      <p class="challenge-variation-text-35"><u><i>GAME CHANGER</i></u></p>
      <p class="challenge-variation-text-35">for seeing results in just 4 weeks!</p>
    </div>
</div>
  <div class="challenge-variation-width-xl px-3 px-md-0">
    <p class="challenge-variation-text-35">Check out what our Challenge participants are saying:</p>
    <app-challenge-testimonials></app-challenge-testimonials>
</div>
  <div #signupForm class="challenge-variation-content-signup challenge-variation-dark-blue-bg-opaque w-100 py-5">
      <p class="challenge-variation-text-35">Our Feel Good Challenge Starts: Monday, September 23rd, 2024</p>
      <p class="challenge-variation-text-50">Sign Up For Free Before You Miss Out!</p>
      <div elementInView (isVisible)="_isMobile && formVisible($event)" class="challenge-variation-width-md mx-auto">
          <div class="my-3">
              <app-timer [main_txt]="'REGISTRATION CLOSES IN...'" [main_theme_color]="'lighter-blue'" [corners]="'border-round'" [is_present]="'d-none'" [main]="'timer--block'" [remainingTime]="timer" [fullTimeName]="true" [numDigits]="'2'"></app-timer>

          </div>
          <div class="challenge-variation-dark-blue-bg">
              <p>Sign Up</p>
              <app-challenge-signup></app-challenge-signup>
          </div>

      </div>
  </div>

  <button #floatingButton *ngIf="_isMobile && showButton" class="challenge-btn floating-btn" type="button" (click)="scrollTo()">Sign Up</button>
</section>
