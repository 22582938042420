import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentUrl = '' as string;
  navContext = '' as string;

  constructor(private router: Router, private sessionService: SessionService) { }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.navContext = this.sessionService.navContext;
  }

  routeToPage(navLink: any) {
    this.router.navigateByUrl(this.navContext + navLink);
  }

  getWWWUrl(uri){
    return environment.wwwUrl + '/' + uri;
  }

}
