<div class="exit-dialog">
    <ng-container *ngIf="data.type == 'firstView' else secondIntent">
        <div mat-dialog-content class="mat-typography exit-dialog-content">
            WAIT! Do You Wanna Try A 9 Minute Customized Follow Along Workout Right Now?
        </div>
        <div mat-dialog-actions>
            <button mat-button mat-dialog-close="Yes" class="exit-dialog-btn exit-dialog-btn--primary">Yes</button>
            <button mat-button mat-dialog-close="No" class="exit-dialog-btn">No</button>
        </div>
    </ng-container>
</div>

<ng-template #secondIntent>
    <div mat-dialog-content class="mat-typography" class="mat-typography exit-dialog-content">
        No problem, We'll Email You A Link To Access Your 9 Minute Customized Follow Along Workout To {{ data.email }}. We Hope You Enjoy It!
    </div>
    <div mat-dialog-actions class="exit-dialog-actions">
        <button mat-button mat-dialog-close="Close" class="exit-dialog-btn exit-dialog-btn--primary">Close</button>
    </div>
</ng-template>