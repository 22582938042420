<!-- <div class="quiz-container">
  <div class="quiz-container_content">
    <app-progress-bar [progressValue]="10"></app-progress-bar>
    <app-header [headerData]="exersiceData[5]"></app-header>
    <app-question-options [questionData]="exersiceData[5]"></app-question-options>
    <app-privacy [privacyQuestion]="exersiceData[4]"></app-privacy>
    <app-circle [questionData]="data[0]"></app-circle>
    <app-video [videoData]="exersiceData"></app-video>
    <app-input></app-input>
  </div>
  <app-bottom-navigation></app-bottom-navigation>
</div> -->

<div id="quiz-wrap" *ngIf="quizResponse">
  <app-survey
  [quizData]="quizResponse"
  (completeEvent)="onComplete($event)"
  (partialEvent)="onPartialEvent($event)"
  (createNewUserEvent)="onCreateNewUser($event)"></app-survey>
</div>

