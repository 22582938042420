import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { interval, Subscription, takeUntil, Subject } from "rxjs";
@Component({
  selector: 'app-timer-sales',
  templateUrl: './timer-sales.component.html',
  styleUrls: ['./timer-sales.component.scss']
})
export class TimerSalesComponent implements OnInit {
  @Input() tagline: string;
  @Input() timerInSeconds: number = 600;
  @Input() buttonText: string;
  @Input() version: string;

  @Output() buttonClicked = new EventEmitter();

  dDay;
  private subscription: Subscription;
  unsubscribe$ = new Subject();
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  minutes: any = '00';
  seconds: any = '00';

  @ViewChild("timer", { static: false }) timerEl: ElementRef;
  constructor() { }

  ngOnInit(): void {
    /** TO DO - MOVE LOGIC TO OWN SERVICE AND SHARED */
    let currentDate = new Date();
    this.dDay = new Date(currentDate.setSeconds(currentDate.getSeconds() + this.timerInSeconds));

    this.subscription = interval(1000).pipe(
      takeUntil(this.unsubscribe$)
    )
      .subscribe(() => { this.getTimeDifference(); });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  getTimeDifference() {
    let timeDifference = this.dDay.getTime() - new Date().getTime();
    if (timeDifference > 0) {
      this.allocateTimeUnits(timeDifference)
    } else {
      this.subscription.unsubscribe();
    }
  }

  allocateTimeUnits(timeDifference) {
    let seconds = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    let minutes = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    if (this.timerEl.nativeElement) {

      let minutesText = minutes >= 10 ? minutes: `0${minutes}`;
      let secondsText = seconds >= 10 ? seconds: `0${seconds}`;

      this.timerEl.nativeElement.innerHTML = `${minutesText}:${secondsText}`;

    }
  }

  ctaClicked() {
    this.buttonClicked.emit(true);
  }

}

