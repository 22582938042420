import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  EventService,
  SessionService,
  SitePageService,
  
} from 'src/app/services/';

@Component({
  selector: 'app-sms-optin',
  templateUrl: './sms-optin.component.html',
  styleUrls: ['./sms-optin.component.scss']
})
export class SmsOptinComponent implements OnInit {
  @Input() header: string;
  @Input() content: string;
  @Input() errorContent: string;

  // Observables passed in
  @Input() signupForm: Observable<any>;
  @Input() processingState: Observable<any>; 

  @Input() processing: boolean = false;
  @Input() submitted: boolean = false;
  @Output() smsPayload = new EventEmitter();
  @Output() closeModalState =  new EventEmitter();


  checkboxes: { terms: boolean, sms: boolean } = {
    terms: false,
    sms: false
  }

  smsForm: FormGroup = this.fb.group({
    phone_number: [''],
    acceptTermsMobile: [false]
  });
  unsubscribe$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private eventService: EventService,
    private sessionService: SessionService,
    private sitePageService: SitePageService
  ) { }

  ngOnInit(): void {
    this.processingState.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe( state => {
      this.submitted = state.submitted;
      this.processing = state.processing;
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  submitForm(e) {
    this.submitted = true;
    e.preventDefault();
    if (this.smsForm.invalid || this.processing) {
      return;
    } else {
      const phone_number = this.smsForm.get('phone_number').value;
      const phone_terms = this.smsForm.get('acceptTermsMobile').value; // SMS Optin

      this.smsPayload.emit({
        phone: phone_number,
        termsAccepted: phone_terms,
        rtClickId: this.sessionService.getCookie("rtkclickid-store"),
      });
    }
  }

  checkCheckBoxvalue(type: string = 'terms') {
    this.checkboxes[type] = !this.checkboxes[type];

    // User clicked sms optin checkbox, make phone number field required
    if (type === 'sms') {
      const phoneControl = this.smsForm.get('phone_number');
      const validators = [Validators.required];
      if (this.f.acceptTermsMobile.value) {
        phoneControl.setValidators(validators);
      } else {
        phoneControl.clearValidators();
      }
      phoneControl.updateValueAndValidity();
    }
  }

  trimSpaces(formControl){
    formControl.setValue(formControl.value.trim());
  }

  getWWWUrl(url) {
    return this.sitePageService.getWWWUrl(url);
  }

  closeModal() {
    this.closeModalState.emit(true);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.smsForm.controls;
  }

  get showMobileTerms() {
    if(this.eventService.isUS())
      return true;
    return false;
  }
}
