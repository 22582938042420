import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpClient
  ) { }

  apiUrl = environment.apiUrl;


  headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );

  /** APIS */
  getTokenUser(token) {
    let apiEndpoint = `${this.apiUrl}/profile/token?token=${token}`;
    return this.http.post<any>(apiEndpoint, {});
  }

  registerNewUser(data, fairy) {
    /***
     * {"firstname":"", "lastname": "", "email":""}
     */
    // console.log(data);
    let apiEndpoint = fairy ? `${this.apiUrl}/profile/register/fairy` : `${this.apiUrl}/profile/register`;
    return this.http.post<any>(apiEndpoint, data);
  }

  registerMasterclassReferral(data) {
    let apiEndpoint = `${environment.apiUrl}/masterclass/referral`;
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post<any>(apiEndpoint, data, {
        headers,
        responseType: 'text' as 'json'
      });
  }

  /** Utilities */


}
