
import { environment } from "src/environments/environment";

export class BaseService
{

    getURL(endpoint:string)
    {
        let apiEndpoint = `${environment.apiUrl}/`+endpoint;
       return apiEndpoint;
    }

    private handleError(error: Response | any) 
    {
        
    }
}