import { Component, OnInit, Input, Output, Inject, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { BillingService, SessionService, DomService, SitePageService } from 'src/app/services';
import { take, Subject, skipWhile, switchMap, of, combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';

import {
  trigger,
  transition,
  style,
  animate
} from '@angular/animations';
import { PaymentMethodComponent } from '../payment-method/payment-method.component';
declare const fbq:Function;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-in-out', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class PaymentComponent implements OnInit {
  @Input() planOptions: any;
  @Input() pageType: any;
  @Input() newUser: any;
  @Input() pageId: string;
  @Input() cssClasses: string;
  @Input() showCheckoutMasterClass: boolean = false;
  @Input() showCheckoutChips: boolean = true;
  @Input() isFairyFunnel: boolean = false;
  window = this._document.defaultView;
  @ViewChild('appPayment') paymentMethod: PaymentMethodComponent;


  // selectedIndex: number;
  recurlyClient = this.window.recurly;
  redtrackID;
  loading: boolean = false;

  /** Subjects */
  unsubscribe$ = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private _document: Document,
    public billingService: BillingService,
    private sessionService: SessionService,
    private domService: DomService,
    private ref: ChangeDetectorRef,
    private zone: NgZone,
    private sitePageService: SitePageService
  ) {
    // Allows redirect to the same post-quiz route for upsell pages
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
   }

  ngOnInit(): void {
    this.checkPaymentItems();
  }

  // setPlanItem(event, scrollToPayment: boolean = true ) {
  //   this.billingService.planSelected = event;
  //   if(this.paymentMethod && scrollToPayment)
  //     this.paymentMethod.scrollIntoView();
  // }

  checkPaymentItems() {
    this.activatedRoute.queryParams.pipe(
      take(1),
      switchMap(params => {
        if (params['selectedPlan']) {
          this.billingService.selectedIndex = parseInt(params['selectedPlan']);
        }
        return combineLatest([of(params), this.billingService.productInfo$]);
      }),
      skipWhile(res => res.some(item => !item)),
      take(1),
    ).subscribe(res => {
      let params = res[0];
      this.billingService.productInformation = res[1];
      this.getRedTrackCookie();
    });
  }

  submitPurchase(purchaseData) {
    this.getRedTrackCookie();

    this.domService.loadingModalBS.next({ display: true, message: "Processing" });
    this.loading = true;

    if (purchaseData.type === 'credit') {
      this.sendCCPurchase(purchaseData['data']);
    }
    else if (purchaseData.type === 'paypal') {
      this.getPayPalAuth();
    }
    else if (purchaseData.type === 'applepay') {
      this.sendApplePayOrder(purchaseData);
    }
  }

  sendCCPurchase(purchaseData) {
    let creditCardEl = purchaseData['creditCardEl'];
    let form = purchaseData['form'];

    this.recurlyClient.token(creditCardEl, form, (err, token) => {
      if (err) {
        // handle error using err.code and err.fields
        if (err.fields.length) {
          let errorMessages = []
          let recurlyErrObj = this.billingService.recurlyInputFieldsBS.getValue();

          err.fields.forEach(field => {
            if (field in recurlyErrObj)
              recurlyErrObj[field].valid = false;

            if (field === 'month') {
              errorMessages.push('Credit Card Month is invalid');
            }
            if (field === 'year') {
              errorMessages.push('Credit Card Year is invalid');
            }
            if (field === 'cvv') {
              errorMessages.push('CVV is invalid');
            }
            if (field === 'postal_code') {
              errorMessages.push('Postal Code is invalid');
            }
          });

          this.billingService.recurlyInputFieldsBS.next(recurlyErrObj);
          this.billingService.paymentErrorsBS.next(errorMessages);
          this.domService.loadingModalBS.next({ display: false });
          this.loading = false;
          console.error(err);
        }
      } else {
        // recurly.js has filled in the 'token' field, so now we can submit the form, Process

        var phoneNumber = null;
        if(form.acceptTermsMobile && form.acceptTermsMobile.checked)
        phoneNumber = form.phone.value;
          let data = {
          planKey: this.billingService.productInformation.plan,
          ccBillingToken: token.id,
          rtClickId: this.redtrackID,
          pageId: this.pageId,
          weshapeToken: this.sessionService.getWeshapeToken(),
          smsPhone: phoneNumber
        }

        this.billingService.submitCreditCardOrder(data).pipe(
          finalize(() => this.domService.loadingModalBS.next({ display: false }))
        ).subscribe({
          next: res => {
            // Successful response, redirect to next page
            if (res && res.nextPageURL) {
              this.updateMetaPixelEvent(this.billingService.productInformation.price);
              // this.router.navigateByUrl(res.nextPageURL);
              this.sitePageService.checkAndRedirectUrl(res.nextPageURL);
              return;
            }
          },
          error: err => {
            this.loading = false;
            // Append error message to form and focus on message
            this.billingService.paymentErrorsBS.next([err.error.message]);
          }
        });
      }

    });
  }

  getPayPalAuth() {
    // Generate Authentication purchase token and will be redirected back to a redirect page for continued processing
    let salesPagePath = location.pathname;
    let redirectPath = `${location.origin}/payment-redirect`;
    let baseAttributes = `spage=${salesPagePath}&selectedPlan=${this.billingService.planSelected.index}&planKey=${this.billingService.productInformation.plan}&pageId=${this.pageId}&wtoken=${this.sessionService.getWeshapeToken()}`;
    let data = {
      price: 1,
      description: this.billingService.productInformation.description,
      successUrl: `${redirectPath}?${baseAttributes}&paymentState=success&paymentType=paypal`,
      cancelUrl: `${redirectPath}?${baseAttributes}&paymentState=cancel&paymentType=paypal`,
      pageId: this.pageId,
      rtClickId: this.redtrackID,
      weshapeToken: this.sessionService.getWeshapeToken()
    }

    this.billingService.getPayPalAuth(data).pipe(
      finalize(() => this.domService.loadingModalBS.next({ display: false }))
    ).subscribe({
      next: res => {
        this.updateMetaPixelEvent(this.billingService.productInformation.price);
        // Got paypal token, redirect user to paypal to complete purchase
        window.location.href = res.nextPageURL;
      },
      error: error => {
        console.error(error);
        this.loading = false;
        this.billingService.paymentErrorsBS.next([error.error.message]);
        this.paymentMethod.resetLegalTerms();
      }
    });
  }

  sendApplePayOrder(purchaseData) {
    let data = {
      token: purchaseData.token,
      planKey: this.billingService.productInformation.plan,
      rtClickId: this.redtrackID,
      pageId: this.pageId,
      weshapeToken: this.sessionService.getWeshapeToken()
    }

    this.zone.run(() => {
      this.domService.loadingModalBS.next({ display: true, message: "Processing" });
      this.ref.detectChanges();
      this.billingService.submitApplePayOrder(data).pipe(
        finalize(() => {
          this.domService.loadingModalBS.next({ display: false });
          this.ref.detectChanges();
        })
      ).subscribe({
        next: res => {
          if (res && res.nextPageURL) {
            this.updateMetaPixelEvent(this.billingService.productInformation.price);
            // this.router.navigateByUrl(res.nextPageURL);
            this.sitePageService.checkAndRedirectUrl(res.nextPageURL);
            this.ref.detectChanges();
            return;
          }
        },
        error: err => {
          this.loading = false;
          // Append error message to form and focus on message
          this.billingService.paymentErrorsBS.next([err.error.message]);
        }
      });
    }); // End of ngzone.run
  }

  getRedTrackCookie() {
    this.redtrackID = this.sessionService.getCookie("rtkclickid-store");
  }

  updateMetaPixelEvent(value) {
    fbq('track', 'Purchase', {
      value: value,
      currency: 'USD'
       });
  }
}
