import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services';

@Component({
  selector: 'app-quiz-version',
  template: ``,
  styles: ['']
})
export class QuizVersionComponent implements OnInit {

  constructor( public route: ActivatedRoute,
    private sessionService:SessionService,
    private router: Router) { }

  ngOnInit(): void {
    if(this.route.snapshot.params && this.route.snapshot.params.slug)
    {
      const slug = this.route.snapshot.params.slug.toLowerCase();
      this.sessionService.setQuizVersionSlug(slug);
    }

    
    let query = this.addQueryParams(this.route.snapshot.queryParams)
    this.router.navigate(['/quiz'], { queryParams: query});
  }

  addQueryParams(queryParams) {
    let paramsObject = {};
    Object.keys(queryParams).forEach(element => {
      paramsObject[element] = queryParams[element]
    });
    return paramsObject;
  }
}
