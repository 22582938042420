export const environment = {
    env: 'staging',
    production: false,
    apiUrl: '/japi',
    thirdPartyTracking: true,
    redTrackUrl: 'https://trk.weshape.com/uniclick.js?attribution=lastpaid&cookiedomain=weshape.com&cookieduration=90&defaultcampaignid=63ab3f71b35924000140f541&regviewonce=false',
    wwwUrl: 'https://influence-remain-062282.framer.app',
    workoutCDN: '/workout-demo',
    productUrl: 'https://my-staging.weshape.com',
  };
