import {Component, ElementRef, ViewChild, Input, OnInit, OnDestroy} from '@angular/core';
import { Subscription, interval } from 'rxjs';
import {Router} from "@angular/router";
@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

  constructor(private router: Router) { }

  @Input() main_theme_color: any; // white, blue, red, pink, lighter-blue, light-blue
  @Input() corners: any; // border-round
  @Input() main: any; // main
  @Input() main_txt: any; // main_txt='LIMITED TIME OFFER,' Primary Text
  @Input() main_txt_second: any; // main_txt_second="Your Discount Ends in" Secondary
  @Input() no_days: any; //d-none
  @Input() container_color: any; // white-container/blue-container/red-container/transparent-container
  @Input() no_time: any;
  @Input() no_names: any; //d-none to show/hide days/Hrs/Min/Sec
  @Input() is_present: any; //d-none to show/hide : in between templates
  @Input() days: any = '00';
  @Input() hours: any = '00';
  @Input() minutes: any = '00';
  @Input() seconds: any = '00';
  @Input() remainingTime: any = '00';
  @Input() fullTimeName: boolean = false;
  @Input() numDigits: string = '1';

  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;

  private subscription: Subscription;

  public dateNow = new Date();
  public dDay: any;

  private getTimeDifference () {
    let timeDifference = this.dDay.getTime() - new Date().getTime();
    if(timeDifference > 0) {
      this.allocateTimeUnits(timeDifference)
    } else {
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
      // this.router.navigate([this.router.url]);
      this.subscription.unsubscribe();
    }
  }

  private allocateTimeUnits (timeDifference) {
    this.seconds = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutes = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hours = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.days = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));


    // Format display time to two digits
    if(this.numDigits === '2') {
      if(this.seconds < 10) {
        this.seconds = `0${this.seconds}`;
      }
      if(this.minutes < 10) {
        this.minutes = `0${this.minutes}`;
      }
      if(this.hours < 10) {
        this.hours = `0${this.hours}`;
      }
      if(this.days < 10) {
        this.days = `0${this.days}`;
      }
    }
  }

  ngOnInit() {
    this.dDay = new Date(this.dateNow.setSeconds(this.dateNow.getSeconds() + parseInt(this.remainingTime)));
    if(parseInt(this.remainingTime) > 0) {
      this.subscription = interval(1000)
        .subscribe(x => { this.getTimeDifference(); });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
