<div #swiperContainer class="container-video-playlist">
    <swiper-container #swiperEl class="swiper-container" init="false">
        <swiper-slide *ngFor="let item of playlist; index as swiperIndex">
            <div class="swiper-slide" [class.active]="item.index === currentActivePlaylist" (click)="doSwitchVideo(item.index, swiperIndex)">
                <div class="playlist-image">
                    <img class="img-fluid" src="{{ item.image }}" alt="{{ item.title }}">
                </div>
                <div class="playlist-title">
                    <span class="playlist-state">
                        <ng-container *ngIf="item.index === currentActivePlaylist">Current Video</ng-container>
                        <ng-container *ngIf="item.index === nextVideo && nextVideo > 0">Next Video</ng-container>
                    </span>
                    <span class="playlist-workout-title">
                        {{ item.title }}
                    </span>
                </div>
            </div>
        </swiper-slide>
    </swiper-container>
    <div class="swiper-button-next" [injectComponent]='true' [inlineSVG]="'assets/images/video-player/chevron-right.svg'"></div>
    <div class="swiper-button-prev" [injectComponent]='true' [inlineSVG]="'assets/images/video-player/chevron-left.svg'"></div>
</div>

