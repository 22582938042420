import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, shareReplay, map } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(private http: HttpClient) { }

  getQuizVersion<T>(slug): Observable<any> {
    let apiEndpoint = `${environment.apiUrl}/quiz/version/` + slug;
    return this.http
      .get<any>(apiEndpoint)
      .pipe(catchError((error) => of({ error: error.message })));
  }

  getFairyQuizVersion<T>(): Observable<any> {
    let apiEndpoint = `${environment.apiUrl}/quiz/fairy-version`;
    return this.http
      .get<any>(apiEndpoint)
      .pipe(catchError((error) => of({ error: error.message })));
  }

  getResponseWithToken<T>(token): Observable<any> {
    let apiEndpoint = `${environment.apiUrl}/quiz/response/` + token;
    return this.http
      .get<any>(apiEndpoint)
      .pipe(catchError((error) => throwError(() => error)));
  }

  getQuizDetails<T>(token): Observable<any> {
    let apiEndpoint = `${environment.apiUrl}/quiz/details/` + token;
    return this.http
      .get<any>(apiEndpoint)
      .pipe(catchError((error) => of({ error: error.message })));
  }

  saveNewQuizResponse<T>(data): Observable<any> {
    let apiEndpoint = `${environment.apiUrl}/quiz/create`;
    return this.http
      .post<any>(apiEndpoint, data);
  }

  updateQuizResponse<T>(data, token): Observable<any> {
    let apiEndpoint = `${environment.apiUrl}/quiz/update/` + token;
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post<any>(apiEndpoint, data, {
        headers,
        responseType: 'text' as 'json'
      })
      .pipe(catchError((error) => of({ error: error.message })));
  }

  smsOptInAndUpdateQuizResponse<T>(data, token): Observable<any> {
    let apiEndpoint = `${environment.apiUrl}/quiz/sms-opt-in/` + token;
    return this.http
      .post<any>(apiEndpoint, data);
  }

  completeQuizResponse<T>(data, token): Observable<any> {
    let apiEndpoint = `${environment.apiUrl}/quiz/complete/` + token;
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http
      .post<any>(apiEndpoint, data)
      .pipe(catchError((error) => of({ error: error.message })));
  }

  getQuizData<T>(apiEndpoint): Observable<any> {
    return this.http.get(apiEndpoint);
  }

}
