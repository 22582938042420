import { Component, Input, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BillingService } from 'src/app/services';


@Component({
  selector: 'app-recurly-credit-card',
  templateUrl: './recurly-credit-card.component.html',
  styleUrls: ['./recurly-credit-card.component.scss']
})
export class RecurlyCreditCardComponent implements OnInit {
  @Input() client;
  @Input() elements;
  @Input() formSubmitted: boolean;
  @Output() recurlyFieldsState = new EventEmitter();


  /** Recurly Integration */
  @ViewChild("recurlyPaymentForm") recurlyPaymentForm: ElementRef;
  @ViewChild("recurlyCardNum") recurlyCardNum: ElementRef;
  @ViewChild("recurlyCardMonth") recurlyCardMonth: ElementRef;
  @ViewChild("recurlyCardYear") recurlyCardYear: ElementRef;
  @ViewChild("recurlyCardCVV") recurlyCardCVV: ElementRef;

  recurlyInputFields;
  creditFieldsRendered: boolean = false;

  unsubscribe$ = new Subject();

  constructor(
    private billingService: BillingService
  ) { }

  ngOnInit(): void {
    this.billingService.recurlyInputFields$.pipe(takeUntil(this.unsubscribe$)).subscribe(item => this.recurlyInputFields = item);

  }

  ngAfterViewInit(): void {
    this.initializeCreditFields();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  initializeCreditFields() {
    // Build out Individual Recurly Card Elements
    const cardNumberElement = this.elements.CardNumberElement({
      style: {
        placeholder: {
          content: "**** **** **** ****"
        }
      }
    });

    const cardMonthElement = this.elements.CardMonthElement({
      inputType: 'select',
      style: {
        placeholder: {
          content: "MM"
        }
      }
    });

    const cardYearElement = this.elements.CardYearElement({
      inputType: 'select',
      style: {
        placeholder: {
          content: "YYYY"
        }
      }
    });

    const cardCvvElement = this.elements.CardCvvElement({
      style: {
        placeholder: {
          content: "CVV"
        }
      }
    });


    cardNumberElement.attach(this.recurlyCardNum.nativeElement);
    cardMonthElement.attach(this.recurlyCardMonth.nativeElement);
    cardYearElement.attach(this.recurlyCardYear.nativeElement);
    cardCvvElement.attach(this.recurlyCardCVV.nativeElement);

    cardNumberElement.on('change', (state) => {
      this.recurlyInputFields['number'].valid = state.valid;
      this.recurlyFieldsState.emit(this.recurlyInputFields);
    });

    cardMonthElement.on('change', (state) => {
      this.recurlyInputFields['month'].valid = state.valid;
      this.recurlyFieldsState.emit(this.recurlyInputFields);
    });

    cardYearElement.on('change', (state) => {
      this.recurlyInputFields['year'].valid = state.valid;
      this.recurlyFieldsState.emit(this.recurlyInputFields);
    });

    cardCvvElement.on('change', (state) => {
      this.recurlyInputFields['cvv'].valid = state.valid;
      this.recurlyFieldsState.emit(this.recurlyInputFields);
    });
  }
}
