import {
  Component,
  OnInit,
  Input,
  Output,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  EventEmitter,
  Inject,
  SimpleChanges
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

import { Subject } from 'rxjs';
import * as videojsPlaylist from 'videojs-playlist';
import { environment } from 'src/environments/environment';

import { workoutPlaylistImages, WorkoutAudio } from 'src/app/shared/components/video-player/workout';
import { PlaylistOverlay, BoxShadow, VideoJSButton, OpenScalingElement } from 'src/app/shared/components/video-player/components';
import { SessionService, SurveyService } from 'src/app/services/';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit {
  window = this._document.defaultView;

  @Input() videoID: string = 'video-player';
  @Input() audioID: string = 'audio-player';
  @Input() workoutDemoItem: any;
  @Input() movementList: any;
  @Input() userInfo: any;

  @Output() videoCompletion = new EventEmitter();
  @Output() sendTag = new EventEmitter();


  @ViewChild('videoPlaylist') playlistElement;
  @ViewChild('videoScalingScreen') videoScalingScreen;
  @ViewChild('audioPlayer') audioEl;

  /* Playlist */
  activePlaylist = [];
  playlistOverlayItems = []; // Items that get displayed on the playlist overlay
  workoutPlaylistImages = workoutPlaylistImages;

  /* Various Player Settings */
  thumbnail: string = '';
  autoplay: boolean = false;

  /** Workout Items */
  dailyWorkout: any;
  iterationKey: any;
  workoutCompletedTime;
  timer: any;
  totalTime: number = 0;
  scalingScreenTimer: number = 6.5; // In seconds how long the scaling screen stays open

  mediaSource = environment.workoutCDN;

  fullscreenState: boolean = false;
  musicMuteState: boolean = false;
  videoPlaylistOverlay: boolean = false;
  poster: string;
  videoPlaylist = [];
  playlistIndex = []; // Maps the Intro, Blocks, and Outro index in activePlaylist
  currentActivePlaylist: number;
  nextVideo: number;
  currentVideoSpot: number;

  /* DEVICE INFO */
  deviceInfo: any;
  isMobile: boolean;

  videoErr: boolean = false;
  unsubscribe$ = new Subject(); // For ngDestroy Unsubscription

  // Video transition
  currentState: string = 'initial';

  videojs = this.window.videojs;
  videoPlayer;


  /** Scaling */
  lvlingObj = {
    movement: '', // Short Code of the movement to level
    index: undefined, // The Index to the video that will require leveling
    lValue: '',
    lLvl: undefined,
    nValue: '',
    update: false, // Boolean to set to true if an update call is required
    leveling: '', // How the user is leveling: up, down, same
    round: 1
  };
  scalingMovementLookup;
  filteredMovement;

  currentBlock: number = 0;
  currentRound: number = 1;
  totalRounds: number = 1;

  /* Video Watch time and event */
  watchTime = {
    "25%": false,
    "50%": false,
    "75%": false,
    "100%": false
  }

  /** Maropost Tag Information */
  maropostTag = {
    time: 5, // Time before event is triggered in minutes
    tag: { tagId: '91' },
    sent: false
  }

  /** Audio Element */
  audioPlayer;
  audioVolume = 0;
  currentAudio;
  WorkoutAudioObj = JSON.parse(JSON.stringify(WorkoutAudio)); // Creating a Copy of the audio object

  constructor(
    private deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) private _document: Document,
    private surveyService: SurveyService,
    private sessionService: SessionService

  ) { }

  ngOnInit() { }

  ngAfterContentInit(): void { }

  ngAfterViewInit(): void {
    this.getWorkout();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userInfo.currentValue) {
      this.userInfo = changes.userInfo.currentValue;
    }

  }
  ngOnDestroy(): void { }

  getWorkout() {
    this.dailyWorkout = this.workoutDemoItem;
    this.audioPlayer = this.audioEl.nativeElement;
    this.audioPlayer.volume = 0;

    this.iterationKey = JSON.parse(this.dailyWorkout['video_iterations']);
    let shortCode = this.dailyWorkout['video_code'];

    let lvl = 1;
    let transcodeName = '';
    let videoLevels = {};
    let videoLevelSet = new Set();
    let transcodeGroups = shortCode.split(';');

    // Calculate the Total Time of the Workout In Seconds
    let tempWorkoutTime = this.dailyWorkout['total_time'].split(':');
    this.workoutCompletedTime = ((parseInt(tempWorkoutTime[0])) * 60) + parseInt(tempWorkoutTime[1]);

    /** Iterate through the short code and build the appropriate strings based on the user's levels */
    transcodeGroups.forEach((group) => {
      transcodeName = '';
      let groupList = group.split(',');
      groupList.forEach((videoFileName) => {
        /** Get the Main Part of the Video File -- MN025, UN025, MT024, etc */
        let shortName = videoFileName.substring(0, 5);
        if (
          videoFileName.startsWith('WU') ||
          videoFileName.startsWith('MT')
        ) {
          if (videoLevels[shortName]) {
            lvl = videoLevels[shortName];
          } else {
            if (!videoLevelSet.has(shortName)) {
              videoLevelSet.add(shortName);
              let getlvl = this.getMvmtLvl(videoFileName);
              if (getlvl == 'None') {
                lvl = 1;
              } else lvl = getlvl;
              videoLevels[shortName] = lvl;
            }
          }

          videoFileName = videoFileName.replace(
            'L^',
            'L' + ('0' + lvl).slice(-2)
          );
          videoFileName = this.getNPrime(videoFileName);
        } else if (
          videoFileName.startsWith('MN') ||
          videoFileName.startsWith('UN')
        ) {
          let tempVideoFileName = videoFileName
            .replace('MN', 'MT')
            .replace('UN', 'MT');
          shortName = shortName
            .replace('MN', 'MT')
            .replace('UN', 'MT');
          if (videoLevels[shortName]) {
            lvl = videoLevels[shortName];
          } else {
            if (!videoLevelSet.has(shortName)) {
              videoLevelSet.add(shortName);
              let getlvl = this.getMvmtLvl(tempVideoFileName);
              if (getlvl == 'None') {
                lvl = 1;
              } else lvl = getlvl;
              videoLevels[shortName] = lvl;
            }
          }

          videoFileName = videoFileName.replace(
            'L^',
            'L' + ('0' + lvl).slice(-2)
          );
          videoFileName = this.getNPrime(videoFileName);
        } else {
          // Get N^
          videoFileName = videoFileName.replace('#', '');
          videoFileName = this.getNPrime(videoFileName);
        }
        if (transcodeName === '') {
          transcodeName = transcodeName + videoFileName;
        } else {
          transcodeName = transcodeName + '_' + videoFileName;
        }
      });

      if (transcodeName != null && transcodeName !== 'undefined') {
        this.videoPlaylist.push({
          src: transcodeName,
          name: transcodeName
        });
      }
    });

    // Build out playlist based on user's workout levels and daily_workout
    this.buildPlaylist();
  }

  buildPlaylist() {
    let blockCount = 1;
    let playlistCount = 0;

    for (let i = 0; i < this.videoPlaylist.length; i++) {
      let poster = '';
      const playlistName = this.videoPlaylist[i]['name'];

      if (playlistName != 'undefined') {
        // Regex to save the index when it finds a match.  Will be used for the playlist for the Intro, Blocks, and Outro
        if (/^(IN|PB|OT)/.test(playlistName)) {
          const playListAudio = [];
          let type = 'Outro';
          if (playlistName.startsWith('IN')) {
            type = 'Introduction';
            playListAudio.push(this.getAudio('intro'));
            playListAudio.push(this.getAudio('warmup'));
          } else if (playlistName.startsWith('PB')) {
            type = `Block ${blockCount++}`;
            playListAudio.push(this.getAudio('block'));
          }

          this.playlistIndex.push({
            title: type,
            index: i,
            nextIndex: this.playlistIndex.length + 1,
            image: `/assets/images/video-player/${this.workoutPlaylistImages[playlistCount]}`,
            audio: playListAudio
          });
          poster = `/assets/images/video-player/WORKOUT_THUMBNAIL.png`,
            playlistCount =
            playlistCount === this.workoutPlaylistImages.length - 1
              ? 0
              : playlistCount + 1;
        }

        this.activePlaylist.push({
          sources: [
            {
              src: `${this.mediaSource}/${this.dailyWorkout.version}/media/${playlistName}.m3u8`,
              type: "application/x-mpegURL"
            }
          ],
          name: playlistName,
          poster: poster
        });
      }
    }

    this.playlistIndex = [...this.playlistIndex];
    this.createVideoPlayer();
  }

  /**
   * Main function to build the Video Player, includes
   * Control bar creation, playlist initialisation, sets custom audio,
   * Defines workout level
   */
  createVideoPlayer() {
    const pointer = this;

    // Set options for the video player
    this.videoPlayer = this.videojs(document.getElementById(this.videoID), {
      controls: true,
      preload: 'auto',
      playsinline: true,
      fluid: true,
      responsive: true,
      userActions: {
        doubleClick: false
      }
    });

    // Construct custom Full Screen button and Music Toggle Button
    let fullScreenButton = new VideoJSButton(this.videoPlayer, {
      clickHandler: (event) => {
        pointer.toggleFullScreen();
      },
      className: "custom-vjs-fullscreen-control"
    });

    let muteMusicButton = new VideoJSButton(this.videoPlayer, {
      clickHandler: (event) => {
        pointer.toggleMusic();
      },
      className: "custom-vjs-music-control"
    });

    /* CREATE PLAYLIST BUTTON WITH CUSTOM STYLES */
    let playlistButton = new VideoJSButton(this.videoPlayer, {
      clickHandler: (event) => {
        pointer.playlistElement.doTogglePlaylist();
      },
      className: "custom-vjs-playlist-control"
    });

    // Register the component with Video.js, so it can be used in players.
    this.videojs.registerComponent('PlaylistOverlay', PlaylistOverlay);
    this.videojs.registerComponent('BoxShadow', BoxShadow);
    this.videojs.registerComponent('OpenScalingElement', OpenScalingElement);

    /** Create New Control Bar */
    this.videoPlayer.removeChild('ControlBar');
    this.videoPlayer.addChild('ControlBar', { children: [] });
    this.videoPlayer.getChild('ControlBar').addChild('BoxShadow');
    this.videoPlayer.getChild('ControlBar').addChild('playToggle');
    this.videoPlayer.getChild('ControlBar').addChild('ProgressControl');
    this.videoPlayer.getChild('ControlBar').addChild('currentTimeDisplay');
    this.videoPlayer.getChild('ControlBar').addChild('muteToggle');
    this.videoPlayer.getChild('ControlBar').addChild('volumeControl');
    this.videoPlayer.getChild('ControlBar').addChild(muteMusicButton);
    this.videoPlayer.getChild('ControlBar').addChild(playlistButton);
    this.videoPlayer.getChild('ControlBar').addChild(fullScreenButton);

    this.videoPlayer.addChild('PlaylistOverlay', { element: pointer.playlistElement.playlistSwiperContainer.nativeElement, playlistComponent: pointer.playlistElement });
    this.videoPlayer.addChild('OpenScalingElement', { element: pointer.videoScalingScreen.openScalingScreenEl.nativeElement });

    this.videojs.registerPlugin('playlist', videojsPlaylist.default);
    this.videoPlayer.playlist(this.activePlaylist);
    this.videoPlayer.playlist.autoadvance(0);

    this.videoPlayer.on('loadedmetadata', function () {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1);
    });


    //Playlist functions
    this.videoPlayer.on('play', function () {
      pointer.playTimer();
      // Keep a separate variable as updated the playlist will lost the current index
      if (this.playlist.currentIndex() > -1) {
        pointer.currentVideoSpot = this.playlist.currentIndex();
      }

      if (pointer.playlistElement) {
        const currentActive = pointer.playlistIndex.findIndex(({ index }) => index === this.playlist.currentIndex());
        pointer.playlistElement.currentActivePlaylist = currentActive;
        pointer.playlistElement.nextVideo = pointer.playlistIndex[currentActive + 1]
          ? pointer.playlistIndex[currentActive + 1].index
          : 0;

        if (currentActive > -1) {
          pointer.currentBlock = currentActive;

          // Change music based on type video (Intro or Block)
          if (pointer.playlistIndex[pointer.currentBlock].audio.length) {
            pointer.currentAudio = pointer.playlistIndex[pointer.currentBlock].audio[0];
            if (!pointer.audioPlayer.src.includes(pointer.currentAudio.source)) {
              pointer.setAudio(pointer.currentAudio);
            }
          }
        } else {
          // Handle warm up music.  Warmup exercises is between 0 and the index of the first block exercise.  Will set warmup music if not currently already playing.
          if (
            this.playlist.currentIndex() <
            pointer.playlistIndex[1].index &&
            !pointer.audioPlayer.src.includes(
              pointer.playlistIndex[0].audio[1].source
            )
          ) {
            pointer.currentAudio = pointer.playlistIndex[0].audio[1];
            pointer.setAudio(pointer.playlistIndex[0].audio[1]);
          }
        }
      }

      pointer.audioPlayer.play();
    });

    this.videoPlayer.on('timeupdate', function () {
      // Get source of current video and compare to the first intro + warmup video.
      // if they are the same and the current duration is less than 10 seconds, do not play the music
      const currentSrc = this.currentSrc();
      if (currentSrc.includes(pointer.activePlaylist[0].name)) {
        pointer.audioPlayer.volume =
          pointer.videoCurrentTime && !pointer.musicMuteState
            ? 0
            : pointer.audioPlayerVolume;
      } else if (currentSrc.includes(pointer.activePlaylist[pointer.activePlaylist.length - 1].name)) {
        // Outro Video has its own music, so mute the currently playing audio
        pointer.audioPlayer.volume = 0;
      } else {
        pointer.audioPlayer.volume = pointer.audioPlayerVolume;
      }



      if (pointer.videoScalingScreen.showOpenScaling && pointer.videoPlayer.currentTime() > 15.000) {
        clearTimeout(pointer.videoScalingScreen.openScalingTimer);
        if (pointer.videoScalingScreen.btnLvlClicked === '')
          pointer.updateWorkoutLevel('same');
        pointer.videoScalingScreen.resetTimerFlags();
        pointer.triggerPlaylistUpdate();
      }
    });

    this.videoPlayer.on('pause', function () {
      pointer.audioPlayer.pause();
      pointer.stopTimer();
    });

    this.videoPlayer.on('seeking', function () {
      pointer.audioPlayer.pause();
    });
    this.videoPlayer.on('seeked', function () {
      pointer.audioPlayer.play();

    });

    this.videoPlayer.on('volumechange', function () {
      let playerMute = this.muted();
      // Handles the mute state for both music and volume buttons
      if (playerMute) {
        pointer.audioPlayer.muted = true;
        !pointer.musicMuteState
          ? document
            .querySelector('.custom-vjs-music-control')
            .classList.add('custom-vjs-music-muted')
          : '';
      } else {
        if (!pointer.musicMuteState) {
          pointer.audioPlayer.muted = false;
          document
            .querySelector('.custom-vjs-music-control')
            .classList.remove('custom-vjs-music-muted');
        }
      }
      pointer.audioPlayer.volume =
        pointer.videoCurrentTime ||
          pointer.audioPlayer.muted ||
          pointer.playlistIndex[
            pointer.playlistIndex.length - 1
          ].title.toLowerCase() === 'outro'
          ? 0
          : pointer.audioPlayerVolume;
    });

    this.videoPlayer.on('ended', function () {
      pointer.stopTimer();
      pointer.audioPlayer.pause();
      const spotNow = pointer.currentVideoSpot;
      const indexCheck = typeof pointer.activePlaylist[spotNow + 1] !== 'undefined'; // Prevents certain code to run since it reached the end of the playlist

      let triggerUpdate =
        !pointer.activePlaylist[spotNow]['name'].startsWith('RW') &&
        !pointer.activePlaylist[spotNow]['name'].startsWith('NR');

      if (triggerUpdate && spotNow !== 0) {
        if (
          indexCheck &&
          (pointer.videoScalingScreen.btnLvlClicked == 'up' || pointer.videoScalingScreen.btnLvlClicked == 'down')
        ) {

          // Update Playlist based on if user clicked on the leveling prompt based on update flag.
          // If true, generate new playlist and set the current video to the current playlist index
          if (pointer.lvlingObj.update) {
            // Update playlist with new levels and update db
            pointer.updateMvmnt(pointer.lvlingObj.lLvl, pointer.lvlingObj.movement);
          }
        } else {
          // Send Just Right Scaling Event
          if (pointer.videoScalingScreen.btnLvlClicked == '') {
            pointer.updateWorkoutLevel('same');
          }
        }
      }

      pointer.scalingMovementLookup = pointer.getWorkoutMovement(spotNow);
      pointer.filteredMovement = pointer.getMovementList(pointer.scalingMovementLookup);
      pointer.filteredMovement = Object.assign({}, pointer.filteredMovement);

      // Slide in Prompt
      const checkDontAskAgain = pointer.checkIfStringStartsWith(
        pointer.videoScalingScreen.scalingObject.movement,
        pointer.videoScalingScreen.dontAskAgain_movement
      );

      if (!checkDontAskAgain) {
        if (indexCheck) {
          const checkName = pointer.activePlaylist[spotNow + 1]['name'];
          const currentName: string = pointer.activePlaylist[spotNow]['name'];

          if (
            checkName.startsWith('RW') ||
            checkName.startsWith('NR') ||
            (checkName.startsWith('UN') && !currentName.startsWith('NR')) ||
            checkName.startsWith('OT') ||
            (checkName.startsWith('#PB') && currentName.startsWith('UN'))
          ) {
            pointer.videoScalingScreen.disablePromptBtns(false); // Enable the prompt buttons
            setTimeout(() => {
              pointer.videoScalingScreen.resetScalingScreenFlags();
              pointer.videoScalingScreen.intensityPrompt = true;
              pointer.videoScalingScreen.showOpenScaling = true;
              pointer.videoScalingScreen.openScalingTimer = setTimeout(function () {
                pointer.videoScalingScreen.resetTimerFlags();
                pointer.triggerPlaylistUpdate();
              }, 18500);

              pointer.videoScalingScreen.startSpinTimmer(pointer.scalingScreenTimer);

              // If user clicks on any prompt button, clear the timeout interval to prevent unwanted UI changes if user scrubs video
              pointer.videoScalingScreen.timerPrompt = setTimeout(() => {
                if (pointer.videoScalingScreen.intensityPrompt) {
                  pointer.videoScalingScreen.clearSpinner();
                  pointer.videoScalingScreen.intensityPrompt = false;
                  pointer.videoScalingScreen.changeState();
                }
              }, pointer.scalingScreenTimer * 1000);
            }, 500);
          }
        }
      } else {
        if (indexCheck) {
          // shows the open scaling on top of the screen when don't ask again is true
          let playlist = pointer.activePlaylist[spotNow + 1]['name'];
          if (
            playlist.startsWith('RW') ||
            playlist.startsWith('NR') ||
            playlist.startsWith('UN')
          ) {
            pointer.videoScalingScreen.showOpenScaling = true;
            pointer.videoScalingScreen.dontAskAgain = true;
            pointer.videoScalingScreen.scalingDownToggle = false;
            pointer.videoScalingScreen.scalingUpToggle = false;
            pointer.videoScalingScreen.scaleDown = 'scaling-down-arrow.svg';
            pointer.videoScalingScreen.scaleUp = 'scaling-up-arrow.svg';
            pointer.videoScalingScreen.openScalingTimer = setTimeout(function () {
              pointer.videoScalingScreen.showOpenScaling = false;
              pointer.videoScalingScreen.clearSpinner();
              pointer.videoScalingScreen.intensityPrompt = false;
              pointer.videoScalingScreen.changeState();
              pointer.triggerPlaylistUpdate();
            }, 15000);
          }
        }
      }

      this.playlist.currentItem(pointer.currentVideoSpot);
    });
  }

  toggleFullScreen() {
    // This function mimic's a browser's video element native fullscreen functionality.
    // Will make the container of the video full screen instead of the video player itself.  Will allow for the workout feedback
    document.querySelector('.custom-vjs-fullscreen-control').classList.toggle('custom-vjs-fullscreen');
    this.fullscreenState = !this.fullscreenState;

    if (this.isMobile && this.fullscreenState) {
      // Scroll to bottom of the video container to "hide" the apps address bar
      document.getElementById('container-video-wrapper').scrollTo(0, 0);
    }

    // Update Swiper Playlist to handle any width changes, etc
    if (this.playlistElement) {
      this.playlistElement.swiperInstance.update();
    }
  }

  setAudio(audio) {
    this.audioPlayer.src = audio.source;
    this.audioPlayer.type = audio.type;
  }

  getAudio(type) {
    // Randomly choose a song from the Audio Array
    const unplayedAudioTracks = this.WorkoutAudioObj[type].filter(({ selected }) => !selected);
    const audio = unplayedAudioTracks[Math.floor(Math.random() * unplayedAudioTracks.length)];

    audio.selected = true;
    audio.source = audio.source;
    return audio;
  }

  get audioPlayerVolume() {
    return this.videoPlayer.volume();
  }

  get videoCurrentTime() {
    return this.videoPlayer.currentTime() < 7.5;
  }

  toggleMusic() {
    this.musicMuteState = !this.musicMuteState;
    this.audioPlayer.muted = this.musicMuteState;

    document.querySelector('.custom-vjs-music-control').classList.toggle('custom-vjs-music-muted');
  }

  doSwitchVideo(playlistIndex) {
    // Switch the video to the item clicked from the workout playlist (intro, blocks, outro)
    this.currentActivePlaylist = playlistIndex;
    this.videoPlayer.playlist.currentItem(playlistIndex);
    if (this.videoPlayer.paused()) {
      this.videoPlayer.play();
    }
  }

  /** MISC */
  getNPrime(videoFileName) {
    return this.iterationKey[videoFileName];
  }

  getMvmtLvl(videoFileName) {
    // Find Appropriate Movement Family and Category to get its level
    let identifier = videoFileName.substring(2, 5);
    let category = videoFileName.startsWith('WU') ? 'WU' : 'MF';

    // Filter out the only movement that has the category/identifier combination
    let foundMovement = this.movementList[category].filter(item => item.identifier === identifier);
    foundMovement = foundMovement[0];
    let movementType = foundMovement.movement_type;
    let filteredType = movementType.toLowerCase();
    let usersLevel = 1;

    // Retrieve the User's Level based on their quiz information
    switch (filteredType) {
      case 'lower':
        usersLevel = parseInt(this.userInfo.response.lower_body_level) || 1;
        break;
      case 'upper':
        usersLevel = parseInt(this.userInfo.response.upper_body_level) || 1;
        break;
      case 'full':
        usersLevel = parseInt(this.userInfo.response.full_body_level) || 1;
        break;
    }

    // If the user's chosen level is higher than the max level of the movement
    // return the max level instead
    return (usersLevel > foundMovement.level)
      ? foundMovement.level
      : usersLevel;
  }

  checkIfStringStartsWith(str, substrs) {
    return substrs.some((substr) => str.startsWith(substr));
  }

  playTimer() {
    // Creates an interval timer to keep track of how long the user has done the workout
    // Will only be kept track of if the user has not completed a workout for the day
    this.timer = window.setInterval(() => {
      this.totalTime += 1;

      // User Watches 5 min of the workout demo, send relevant tag to maropost
      const workoutWatchedTagTime = this.maropostTag.time * 60; // Time in Seconds
      if((this.totalTime > workoutWatchedTagTime) && !this.maropostTag.sent) {
        this.sendTag.emit(this.maropostTag.tag);
        this.maropostTag.sent = true;
      }

      let percentageWatched = (this.totalTime / this.workoutCompletedTime) * 100;
      switch (true) {
        case (percentageWatched >= 100) && !this.watchTime["100%"]:
          this.sendWatchTime("100%");
          break;
        case (percentageWatched >= 75) && !this.watchTime["75%"]:
          this.sendWatchTime("75%");
          break;
        case (percentageWatched >= 50) && !this.watchTime["50%"]:
          this.sendWatchTime("50%");
          break;
        case (percentageWatched >= 25) && !this.watchTime["25%"]:
          this.sendWatchTime("25%");
          break;
      }


      if (this.totalTime >= this.workoutCompletedTime) {
        clearInterval(this.timer);
        this.totalTime = 0;
      }

    }, 1000);
  }

  stopTimer() {
    if (this.timer) clearInterval(this.timer);
  }

  /** LEVELING */
  updateWorkoutLevel(leveling: string = 'same') {

    // Clear the timer from the prompt that was set during end of video
    if (typeof this.videoScalingScreen.timerPrompt !== 'undefined') {
      clearTimeout(this.videoScalingScreen.timerPrompt);
    }

    if (leveling === 'up') {
      this.lvlingObj.leveling = 'too easy';
      this.videoScalingScreen.scaleUp = 'check-mark.svg';
      this.videoScalingScreen.scaleDown = 'scaling-down-arrow.svg';
    } else if (leveling === 'down') {
      this.lvlingObj.leveling = 'too hard';
      this.videoScalingScreen.scaleDown = 'check-mark.svg';
      this.videoScalingScreen.scaleUp = 'scaling-up-arrow.svg';
    } else if (leveling === 'same') {
      this.lvlingObj.leveling = 'just right';
    }

    // takes the given leveling parameter from the button click to set the correct/new workout level
    const updateFlag = leveling === 'up' || leveling === 'down';
    this.lvlingObj.update = updateFlag;
    this.lvlingObj.round = this.videoScalingScreen.currentRound;
    this.videoScalingScreen.btnLvlClicked = leveling;

    this.lvlingObj.index = this.videoPlayer.playlist.currentItem() - 1; // Video is the previous video due to the prompt appearing after the video finishes playing

    this.lvlingObj.movement = this.getWorkoutMovement(this.lvlingObj.index); //Get the movement for prompted workout

    if (
      this.lvlingObj.movement.startsWith('RW') ||
      this.lvlingObj.movement.startsWith('BI') ||
      this.lvlingObj.movement.startsWith('NR')
    ) {
      this.lvlingObj.movement = this.getWorkoutMovement(this.lvlingObj.index - 1);
    }

    if (this.lvlingObj.movement != '') {
      this.lvlingObj.lValue = this.lvlingObj.movement.slice(6, 9);
      this.lvlingObj.lLvl = parseInt(this.lvlingObj.lValue.slice(1, 3));
      this.lvlingObj.nValue = this.lvlingObj.movement.slice(13, 16);
      let mvmtLst = this.getMovementList(this.lvlingObj.movement);
      // Workouts can only be in the range of 1 to 9
      // Hit min: If max level is 10 & current level is 10 & user clicks on too easy its hit max
      // Hit max: If max level is 10 & current level is 1 & user clicks on too hard its hit min
      if (mvmtLst) {
        if (leveling === 'up') {
          if (this.lvlingObj.lLvl < 10) {
            this.lvlingObj.lLvl += 1;
            this.lvlingObj.update = true;
            this.lvlingObj.leveling = 'too easy';
          }
        } else if (leveling === 'down') {
          if (this.lvlingObj.lLvl > 1) {
            this.lvlingObj.lLvl -= 1;
            this.lvlingObj.update = true;
            this.lvlingObj.leveling = 'too hard';
          }
        } else if (leveling === 'same') { }
      }

      // Warm Up Exercises has a max level of 3
      if (
        this.lvlingObj.movement.startsWith('WU') &&
        this.lvlingObj.lLvl > 3
      ) {
        this.lvlingObj.lLvl = 3;
      }
    }
  }

  getMovementList(movement) {
    let category = movement.startsWith('WU') ? 'WU' : 'MF';
    let identifier = movement.slice(2, 5);
    let returnList = this.movementList[category].filter(item => item.identifier === identifier);
    return returnList[0];
  }

  updateMvmnt(level: number, workoutMovement: string) {
    let mvmtLst = this.getMovementList(workoutMovement);

    /** Call Backend To Update User's Upper/Lower/Full Body Level */
    let movementType = (mvmtLst.movement_type).toLowerCase();
    let fieldName = "";

    // Only Update User's information if the level is lower than the maximum allowed for the movement
    if (level <= mvmtLst.level) {
      // Retrieve the User's Level based on their quiz information
      switch (movementType) {
        case 'lower':
          fieldName = "lower_body_level";
          break;
        case 'upper':
          fieldName = "upper_body_level";
          break;
        case 'full':
          fieldName = "full_body_level";
          break;
        default:
          fieldName = "none";
          break;
      }

      if (fieldName != "none") {
        let data = this.userInfo.response;
        data[fieldName] = level;
        let token = this.userInfo.weshapeToken;

        this.surveyService.updateQuizResponse(data, token).subscribe(res => {
          // console.log(res);
          this.lvlingObj.update = false;
          this.sessionService.setUserQuizResponse(this.userInfo)
        });
      }
    }
  }

  updatePlaylist(
    workoutMovement: string,
    newName: string,
    lLvl: number
  ) {
    // Updates the active playlist with the higher or lower level that is passed
    let workoutSlice = workoutMovement.slice(0, 8);
    let mvmntNameSlice = workoutSlice.replace('MT', 'MN');
    this.activePlaylist.filter((element, index) => {
      // Finds all of the workout elements in the array that matches the workout movement to adjust
      if (
        element['name'].includes(workoutSlice) ||
        element['name'].includes(mvmntNameSlice)
      ) {
        // If the workout element is greater than 20 than it includes multiple elements, this isolates and handles them individually
        if (element['name'].length > 20) {
          let nameLst = element['name'].match(/.{1,21}/g);
          nameLst.forEach((name) => {
            if (
              name.includes(workoutSlice) ||
              name.includes(mvmntNameSlice) ||
              name.startsWith('UN')
            ) {
              if (
                !name.startsWith('BI') &&
                !name.startsWith('NR')
              ) {
                let plValue = name.slice(6, 9);
                let pNValue = name.slice(13, 16);
                let lastIndex = name.lastIndexOf(pNValue);
                let lookupName = name.replace(
                  plValue,
                  'L' + ('0' + lLvl).slice(-2)
                );
                lookupName =
                  lookupName.substring(0, lastIndex) +
                  'N^' +
                  lookupName.substring(
                    lastIndex + 3,
                    lookupName.length
                  );
                if (lookupName.length == 20) {
                  name = name.slice(0, name.length - 1);
                  lookupName = lookupName.slice(
                    0,
                    lookupName.length - 1
                  );
                }
                let pNewName = this.getNPrime(lookupName);
                if (pNewName) {
                  element['sources'][0]['src'] = element[
                    'sources'
                  ][0]['src'].replace(name, pNewName);
                  element['name'] = element['name'].replace(
                    name,
                    pNewName
                  );
                } else {
                  this.activePlaylist[index] = element;
                }
              }
            }
          });

          // Need to also search for #PB names MN values
        } else {
          element['sources'][0]['src'] = element['sources'][0][
            'src'
          ].replace(workoutMovement, newName);
          element['name'] = element['name'].replace(
            workoutMovement,
            newName
          );
          this.activePlaylist[index] = element;
        }
      }
    });
  }

  callUpdatePlaylist(workoutMovement: string, valuedict) {
    const lookupName = workoutMovement
      .replace(valuedict.lValue, 'L' + ('0' + valuedict.lLvl).slice(-2))
      .replace(valuedict.nValue, 'N^');
    const newName = this.getNPrime(lookupName);
    if (newName) {
      this.updatePlaylist(workoutMovement, newName, valuedict.lLvl);
    }

    if (!workoutMovement.startsWith('WU')) {
      workoutMovement = workoutMovement.replace('MT', 'MN');
      const lookupName = workoutMovement
        .replace(
          valuedict.lValue,
          'L' + ('0' + valuedict.lLvl).slice(-2)
        )
        .replace(valuedict.nValue, 'N^');
      const newName = this.getNPrime(lookupName);
      if (newName) {
        this.updatePlaylist(workoutMovement, newName, valuedict.lLvl);
      }
    }
  }

  triggerPlaylistUpdate() {
    const indexCheck = typeof this.activePlaylist[this.currentVideoSpot + 1] !== 'undefined'; // Prevents certain code to run since it reached the end of the playlist
    const levelCheck = this.videoScalingScreen.btnLvlClicked == 'up' || this.videoScalingScreen.btnLvlClicked == 'down';
    let triggerUpdate =
      !this.activePlaylist[this.currentVideoSpot]['name'].startsWith('RW') &&
      !this.activePlaylist[this.currentVideoSpot]['name'].startsWith('NR');

    if (triggerUpdate && this.currentVideoSpot !== 0) {
      if (indexCheck && levelCheck) {
        this.updateWorkoutLevel(this.videoScalingScreen.btnLvlClicked);
        if (this.lvlingObj.update) {
          // Generate new playlist with the new level and Replace current playlist with the new updated on
          this.callUpdatePlaylist(this.lvlingObj.movement, this.lvlingObj);
          this.videoPlayer.playlist(this.activePlaylist, -1);
        }
      }
    }
  }

  getWorkoutMovement(spotNow: number) {
    let previousVideo =
      spotNow <= 1
        ? this.activePlaylist[0]
        : this.activePlaylist[spotNow];
    let workoutName = previousVideo['name'].match(/.{1,21}/g);
    return workoutName.length > 1 ? workoutName[1] : workoutName[0];
  }


  /** Events */
  sendWatchTime(percentage) {
    // Call to POST in the backend when this method gets called
    this.watchTime[percentage] = true;
    this.videoCompletion.emit({"demo_watch_time": percentage});
  }
}
