<div class="promo-timer-container {{main_theme_color}} {{corners}} {{main}}">
  <span class="main-txt">{{main_txt}}</span>
  <span class="main-txt">{{main_txt_second}}</span>
  <div class="time-wrap">
    <div class="num-txt-wrap {{no_days}} {{container_color}}">
      <span id="days">{{days}}</span>
      <span class="num-txt-wrap-type {{no_names}}">
        <ng-container [ngSwitch]="fullTimeName">
          <ng-container *ngSwitchCase="true">
            Days
          </ng-container>
          <ng-container *ngSwitchDefault>
            Days
          </ng-container>
        </ng-container>
      </span>
    </div>
    <span class="semicolon {{is_present}} {{no_days}}">:</span>
    <div class="num-txt-wrap {{container_color}}">
      <span id="hrs">{{hours}}</span>
      <span class="num-txt-wrap-type {{no_names}}">
        <ng-container [ngSwitch]="fullTimeName">
          <ng-container *ngSwitchCase="true">
            Hours
          </ng-container>
          <ng-container *ngSwitchDefault>
            Hrs
          </ng-container>
        </ng-container>
      </span>
    </div>
    <span class="semicolon {{is_present}}">:</span>
    <div class="num-txt-wrap {{container_color}}">
      <span id="mins">{{minutes}}</span>
      <span class="num-txt-wrap-type {{no_names}}">
        <ng-container [ngSwitch]="fullTimeName">
          <ng-container *ngSwitchCase="true">
            Minutes
          </ng-container>
          <ng-container *ngSwitchDefault>
            Min
          </ng-container>
        </ng-container>
      </span>
    </div>
    <span class="semicolon {{is_present}}">:</span>
    <div class="num-txt-wrap {{container_color}}">
      <span id="sec">{{seconds}}</span>
      <span class="num-txt-wrap-type {{no_names}}">
        <ng-container [ngSwitch]="fullTimeName">
          <ng-container *ngSwitchCase="true">
            Seconds
          </ng-container>
          <ng-container *ngSwitchDefault>
            Sec
          </ng-container>
        </ng-container>
      </span>
    </div>
  </div>
</div>
