import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { SwiperOptions, Swiper } from 'swiper';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-challenge-testimonials',
  templateUrl: './challenge-testimonials.component.html',
  styleUrls: ['./challenge-testimonials.component.scss']
})
export class ChallengeTestimonialsComponent implements OnInit {
  @Input() slides: any[] = [
    {
      quote: 'A breath of fresh air! WeShape has upended the system on being able to actually help people feel good in their bodies. They take a holistic approach to movement and address so much behind the scenes that impact our ability to move. They are creating a community that helps sustain each member in both obvious and unseen ways.',
      name: 'Dana'
    },
    {
      quote: 'Incredible coaching! I took the Challenge because I’ve seen Coach Tyler’s clips helping people with real problems. My body almost immediately felt the difference. But also very important is that they remind us that we need to love our selfs. They way they do encouragement is a paramount positive difference amongst other programs. I’m thankful to Coach Tyler and the WeShape Team for their support!',
      name: 'Keri'
    },
    {
      quote: 'When I started the program I was leery and skeptical as with any new exercise program. However following the outline, workouts, challenges and joining in WITH the WeShape community has allowed me to see that I am beautiful the way I am and my body has served as a vessel that has worked hard for me to get me to who I am today. I have turned down the negative internal criticism. I truly wish I would have learned this years ago!',
      name: 'Dawn'
    },
    {
      quote: 'WeShape, thank you! This program is the best I’ve ever tried. I love the way you help me to see me in a better light. Continue to do what you do... Thank you.',
      name: 'Robyn'
    },
    {
      quote: 'Growth of body AND mind! I began using WeShape to help me in feeling better in my body through increasingly challenging guided movements. As an added bonus, I find the podcasts that help develop healthy thoughts and generate peace perfectly complement the movements that build strength, flexibility, balance and confidence. They go hand in hand.',
      name: 'James'
    }
  ];

  public slideIndex: number = 0;
  public showSwiper: boolean = false;

  swiperConfig: SwiperOptions;
  swiperInstance;
  @ViewChild('swiperEl', { static: false }) swiperRef?: ElementRef;

  constructor() { }

  ngOnInit(): void {
    register();
  }

  ngAfterViewInit() {
    this.doSwiperConfig();
  }

  doSwiperConfig() {
    this.swiperConfig = {
      direction: 'horizontal',
      loop: true,
      effect: 'slide',
      slidesPerView: 1,
      spaceBetween: 20,      
      centeredSlides: true,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        // when window width is >= 678px
        678: {
          slidesPerView: 2,
          spaceBetween: 35
        }
      }
    };

    Object.assign(this.swiperRef?.nativeElement, this.swiperConfig);
    this.swiperRef?.nativeElement.initialize();
    this.swiperInstance = this.swiperRef?.nativeElement?.swiper;
  }

}
