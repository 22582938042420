import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-challenge-v008',
  templateUrl: './challenge-v008.component.html',
  styleUrls: ['./challenge-v008.component.scss']
})
export class ChallengeV008Component implements OnInit {
  @Input() timer;

  @Input() set isMobile(value: boolean) {
    this._isMobile = value;
  }

  _isMobile: boolean = false;
  @ViewChild('signupForm') signupForm?: ElementRef;

  constructor() { }

  ngOnInit(): void {

  }

  scrollTo() {
    this.signupForm.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
