<div #openScalingScreen [hidden]="!showOpenScaling" [@inOutAnimation] class="scaling-open-prompt" (click)="openScaling()">
    <div class="scaling-open">
        <span [injectComponent]='true' inlineSVG="assets/images/directional-arrow-down.svg"></span>
    </div>
    <p class="scaling-open-text">Open Scaling</p>
</div>
<div *ngIf="intensityPrompt" [@slideInOutAnimation] class="scaling-prompt">
    <div class="scaling">
        <div class="scaling-spinner" *ngIf="spinnerTimerValue < 100">
            <mat-progress-spinner mode="determinate" diameter="30" [value]="spinnerTimerValue"></mat-progress-spinner>
        </div>
        <p class="scaling-title">Next time you see this movement...</p>
        <p class="scaling-title-sub">Don't press anything if your feel this movement is right for you.</p>
        <div class="scaling-btns">
            <div>
                <button [disabled]="showLowestAlert" class="scaling-btns-up" type="button" (click)="scalingUpdateCheck('down')" [ngClass]="{'active': scalingDownToggle}">
                    <span [hidden]="showLowestAlert" class="scaling-btns-up-arrow" [injectComponent]='true' inlineSVG="assets/images/{{scaleDown}}"></span>Scale Down</button>
            </div>
            <div>
                <button [disabled]="showHighestAlert" class="scaling-btns-down" type="button" (click)="scalingUpdateCheck('up')" [ngClass]="{'active': scalingUpToggle }">Scale Up
                    <span [hidden]="showHighestAlert" class="scaling-btns-down-arrow" [injectComponent]='true' inlineSVG="assets/images/{{scaleUp}}"></span></button>
            </div>
        </div>
        <div class="scaling-texts">
            <span class="scaling-texts-lowest" [ngClass]="{'hidden': !showLowestAlert}">You are on the lowest level.</span>
            <span class="scaling-texts-top" [ngClass]="{'hidden': !showHighestAlert}">You are on the top level.</span>
        </div>
        <div class="scaling-dont-ask">
            <button class="scaling-dont-ask-btn" type="button" (click)="checkBoxChecked()" [ngClass]="{'active': dontAskAgain}">
                <span [hidden]="!dontAskAgain" class="scaling-btns-down-arrow" [injectComponent]='true' inlineSVG="assets/images/check-mark.svg"></span>Don't ask again for this movement today.</button>
        </div>
        <div class="scaling-closing" (click)="closeScaling()">
            <p class="scaling-closing-text">Close Scaling</p>
            <span [injectComponent]='true' inlineSVG="assets/images/directional-arrow-up.svg"></span>
        </div>
    </div>
</div>