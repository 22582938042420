import { Component, OnInit, Input, Output } from '@angular/core';
import { ChallengeService, SessionService, SitePageService } from 'src/app/services/';
import { ChallengeComponent } from '../challenge.component';

import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, skipWhile } from 'rxjs/operators';

@Component({
  selector: 'app-challenge-signup',
  templateUrl: './challenge-signup.component.html',
  styleUrls: ['./challenge-signup.component.scss']
})
export class ChallengeSignupComponent {
  challengeForm: FormGroup = this.fb.group({
    firstName: [
      '',
      [
        Validators.required,
        Validators.pattern(
          /^[A-ZÀ-ÿa-z0-9]+((\s)?((\'|\-|\.|\+|\@)?([A-ZÀ-ÿa-z0-9])+))*$/
        ),
      ],
    ],
    lastName: [
      '',
      [
        Validators.required,
        Validators.pattern(
          /^[A-ZÀ-ÿa-z0-9]+((\s)?((\'|\-|\.|\+|\@)?([A-ZÀ-ÿa-z0-9])+))*$/
        ),
      ],
    ],
    email: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'
        ),
      ],
    ],
    description: [
      '',
      [
        // Validators.required,
        Validators.pattern(
          /^(?=.*\S)[\s\S]+$/
        ),
      ],
    ],
    phone_number: [''],
    acceptTerms: [false, Validators.requiredTrue],
    acceptTermsMobile: [false]
  });

  submitted = false;
  processing = false;

  errorContent: string = '';
  checked: boolean = false;
  checkboxes: { terms: boolean, sms: boolean } = {
    terms: false,
    sms: false
  }
  existingMember: boolean = false;
  unsubscribe$ = new Subject();
  // processingState$: Observable<any>;

  constructor(
    private session: SessionService,
    private fb: FormBuilder,
    private sitePageService: SitePageService,
    private challengeService: ChallengeService
  ) { }

  ngOnInit(): void {    
    // Initialize listener for possible form changes
    combineLatest([
      this.challengeService.signupForm$,
      this.challengeService.processingState$
    ]).pipe(
      skipWhile( res => res.some( item => !item ) ),
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      let [ signupForm, processingState ] = res;
      this.processing = processingState.processing;
      this.submitted = processingState.submitted;
      this.errorContent = processingState.message;

      let continueProcessing = !!(processingState.type.indexOf('signup') || processingState.type.indexOf(''));

      // Prevent multiple calls if signup is currently processing
      if(continueProcessing && !processingState.submitted) {
        this.updateValues(signupForm);
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  updateValues(response) {
    this.challengeForm.patchValue({
      firstName: response?.firstName,
      lastName: response?.lastName,
      email: response?.email,
      phone_number: response?.phone,
      description: response?.description || '',
    });
    if (
      this.challengeForm.value.firstName &&
      this.challengeForm.value.lastName &&
      this.challengeForm.value.email
    ) {
      ChallengeComponent.EXISTING_MEMBER = true;
      this.challengeForm.controls.firstName.disable();
      this.challengeForm.controls.lastName.disable();
      this.challengeForm.controls.email.disable();
    }
  }

  submitForm(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.challengeForm.invalid || this.processing) {
      return;
    } else {
      const firstName = this.challengeForm.get('firstName').value;
      const lastName = this.challengeForm.get('lastName').value;
      const email = this.challengeForm.get('email').value;
      const phone_number = this.challengeForm.get('phone_number').value;
      const description = this.challengeForm.get('description').value;
      const phone_terms = this.challengeForm.get('acceptTermsMobile').value; // SMS Optin

      let data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone_number,
        description: description,
        rtClickId: this.session.getCookie("rtkclickid-store"),
        termsAccepted: phone_terms
      };

      this.challengeService.processingStateBS.next({ type: 'signup', submitted: true, processing: false, message: '' });
      this.challengeService.signupFormBS.next(data);
    }
  }
  trimSpaces(formControl) {
    formControl.setValue(formControl.value.trim());
  }

  checkCheckBoxvalue(type: string = 'terms') {
    this.checkboxes[type] = !this.checkboxes[type];

    // User clicked sms optin checkbox, make phone number field required
    if (type === 'sms') {
      const phoneControl = this.challengeForm.get('phone_number');
      const validators = [Validators.required];
      if (this.f.acceptTermsMobile.value) {
        phoneControl.setValidators(validators);
      } else {
        phoneControl.clearValidators();
      }
      phoneControl.updateValueAndValidity();
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.challengeForm.controls;
  }

  getWWWUrl(url) {
    return this.sitePageService.getWWWUrl(url);
  }
}
