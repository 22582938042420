<div #registerContainer class="register container {{cssClasses}} p-4" [hidden]="hideRegistration">
  <legend>1. Register</legend>

  <div class="row">
    <form [formGroup]="registerForm" (ngSubmit)="registerUser($event)">
      <div class="card mb-4">
        <div class="card-body">
          <div class="row">
            <div class="col col-md-6">
              <div class="mb-3">
                <label for="firstname" class="form-label">First Name</label>
                <input type="text" class="form-control" id="firstname" formControlName="firstname" placeholder="First name*" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"
                 (change)="trimSpaces(registerForm.get('firstname'))"/>
                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                  <div *ngIf="f.firstname.errors.required">Full name is required</div>
                </div>
              </div>
            </div>
            <div class="col col-md-6">
              <div class="mb-3">
                <label for="lastname" class="form-label">Last Name</label>
                <input type="text" class="form-control" id="lastname" formControlName="lastname" placeholder="Last name*" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"
                  (change)="trimSpaces(registerForm.get('lastname'))"/>
                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                  <div *ngIf="f.lastname.errors.required">Last name is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-12">
              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email*"[ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                 (change)="trimSpaces(registerForm.get('email'))"/>
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.pattern">Please enter a valid email address</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-container">
        <button type="submit" class="btn register-button">
          <span class="register-loading" *ngIf="loading">
            <mat-spinner diameter="20" strokeWidth="5"></mat-spinner>
          </span>Register</button>
      </div>
    </form>
  </div>
</div>
