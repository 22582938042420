import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
@Component({
  selector: 'app-challenge-v010',
  templateUrl: './challenge-v010.component.html',
  styleUrls: ['./challenge-v010.component.scss']
})
export class ChallengeV010Component implements OnInit {
  @Input() timer;
  @Input() set isMobile(value: boolean) {
    this._isMobile = value;
  }

  _isMobile: boolean = false;
  showButton: boolean = true;

  @ViewChild('floatingButton') floatingButton?: ElementRef;
  @ViewChild('signupForm') signupForm?: ElementRef;


  constructor() { }

  ngOnInit(): void { }

  formVisible(isVisible) {
    this.showButton = !isVisible;
  }

  scrollTo() {
    this.signupForm.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
