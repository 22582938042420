<section class="challenge-variation challenge-variation-page-bg-blue">
  <div class="container text-center">
    <img class="mb-4" src="/assets/images/challenge-optin/weshape-logo.png" alt="WeShape Logo" />
    <p class="challenge-variation-text-50 text-dark-blue">Want to improve your physical and mental health in just 28 days?</p>
    <p class="challenge-variation-text-35 text-dark-blue no-bold">This Fitness Challenge will help you transform your life! And the best part is...</p>
    <p class="challenge-variation-bg-lighter-blue challenge-variation-text-35 text-dark-blue wt-600">You can join 100% for free,<br/>NO CREDIT CARD REQUIRED!</p>
    <p class="challenge-variation-text-50 text-dark-blue mt-4">When you sign up today, you’ll get access to:</p>
    <div class="container">
      <!-- Workouts -->
      <div class="challenge-variation-bg-light-blue row">
        <p class="challenge-variation-text-40 text-dark-blue d-md-none">Workouts</p>
        <div class="col-12 col-md-6">
          <img src="/assets/images/challenge-optin/workouts.png" class="img-fluid" alt="Workouts image">
        </div>
        <div class="col-12 col-md-6">
          <p class="challenge-variation-text-40 text-dark-blue mt-2 d-none d-md-block">Workouts</p>
          <ul class="challenge-variation-in-product-list">
            <li><img class="checkFat" src="/assets/images/challenge-optin/checkFat.svg"> 28 days of Customized Workouts</li>
            <li><img class="checkFat" src="/assets/images/challenge-optin/checkFat.svg"> Workout Scaling - ability to increase or decrease the difficulty throughout your workout!</li>
          </ul>
          <div class="challenge-variation-bg-blue">
            <ul class="challenge-variation-fav-part">
              <li>
                <img class="heart" src="/assets/images/challenge-optin/heart.svg">
                <p class="px-2 challenge-variation-text-gray-20 text-start">OUR FAVORITE PART
                  <br/><span class="challenge-variation-text-white-20">Failure is not an option!</span>
                </p>
              </li>
            </ul>
            <p class="challenge-variation-text-white-20">At WeShape, we believe doing your best varies day by day! So honor your efforts, and offer yourself grace whenever necessary!</p>
          </div>
        </div>
      </div>
      <!-- Community -->
      <div class="challenge-variation-bg-light-blue row mt-4">
        <p class="challenge-variation-text-40 text-dark-blue d-md-none">Community</p>
        <div class="col-12 col-md-6">
          <img src="/assets/images/challenge-optin/community.png" class="img-fluid" alt="Community image">
        </div>
        <div class="col-12 col-md-6">
          <p class="challenge-variation-text-40 text-dark-blue mt-2 d-none d-md-block">Community</p>
          <ul class="challenge-variation-in-product-list">
            <li><img class="checkFat" src="/assets/images/challenge-optin/checkFat.svg"> A Community Forum to share your wins and feel supported!</li>
            <li><img class="checkFat" src="/assets/images/challenge-optin/checkFat.svg"> Weekly Zoom Sessions to connect with our Coaches and other participants!</li>
          </ul>
          <div class="challenge-variation-bg-blue">
            <ul class="challenge-variation-fav-part">
              <li>
                <img class="heart" src="/assets/images/challenge-optin/heart.svg">
                <p class="px-2 challenge-variation-text-gray-20 text-start">OUR FAVORITE PART
                  <br/><span class="challenge-variation-text-white-20">The Inspiration You’ll Gain</span>
                </p>
              </li>
            </ul>
            <p class="challenge-variation-text-white-20">At WeShape, we find motivation through the power of connection. Hearing others' stories and experiences inspires us, showing that we are not alone in our journeys </p>
          </div>
        </div>
      </div>
      <!-- Weekly Challenge Tasks -->
      <div class="challenge-variation-bg-light-blue row mt-4">
        <p class="challenge-variation-text-40 text-dark-blue d-md-none">Weekly Challenge Tasks</p>
        <div class="col-12 col-md-6">
          <img src="/assets/images/challenge-optin/weeklyChallengeTasks.png" class="img-fluid" alt="Weekly Challenge Tasks image">
        </div>
        <div class="col-12 col-md-6">
          <p class="challenge-variation-text-40 text-dark-blue mt-2 d-none d-md-block">Weekly Challenge Tasks</p>
          <ul class="challenge-variation-in-product-list">
            <li><img class="checkFat" src="/assets/images/challenge-optin/checkFat.svg"> Keep your motivation going by participating in fun, weekly submissions!</li>
            <li><img class="checkFat" src="/assets/images/challenge-optin/checkFat.svg"> Track your progress, and earn points towards winning one of our amazing prizes!</li>
          </ul>
          <div class="challenge-variation-bg-blue">
            <ul class="challenge-variation-fav-part">
              <li>
                <img class="heart" src="/assets/images/challenge-optin/heart.svg">
                <p class="px-2 challenge-variation-text-gray-20 text-start">OUR FAVORITE PART
                  <br/><span class="challenge-variation-text-white-20">Our Proven 4 Pillar Approach</span>
                </p>
              </li>
            </ul>
            <p class="challenge-variation-text-white-20">Each week, we will guide you through one of our 4 pillars! Each pillar will provide you with new tools to learn how to connect with and feel AMAZING in your body!</p>
          </div>
        </div>
      </div>
    </div>
    <p class="challenge-variation-text-35 text-dark-blue mt-4">And, if you want to learn more about how incredible this Challenge is...</p>
    <p class="challenge-variation-text-35 text-blue">Make sure to read below to see what other’s are saying!</p>
    <app-challenge-testimonials></app-challenge-testimonials>
  </div>
  <div #signupForm class="challenge-variation-content-signup challenge-variation-light-blue-bg-gradient w-100 py-5">
    <p class="challenge-variation-text-35 text-darker-blue">Our Feel Good Challenge Starts: Monday, July 15th, 2024</p>
    <p class="challenge-variation-text-50 text-darker-blue">Sign Up <b>FOR FREE</b> Before You Miss Out!</p>
    <div elementInView (isVisible)="_isMobile && formVisible($event)" class="challenge-variation-width-md mx-auto">
        <div class="my-3">
            <app-timer [main_txt]="'REGISTRATION CLOSES IN...'" [main_theme_color]="'lighter-blue-black-text'" [corners]="'border-round'" [is_present]="'d-none'" [main]="'timer--block'" [remainingTime]="timer" [fullTimeName]="true" [numDigits]="'2'"></app-timer>

        </div>
        <div class="challenge-variation-dark-blue-bg">
            <p>Sign Up</p>
            <app-challenge-signup></app-challenge-signup>
        </div>

    </div>
  </div>
  <button #floatingButton *ngIf="_isMobile && showButton" class="challenge-btn floating-btn" type="button" (click)="scrollTo()">Sign Up</button>
</section>
