<nav class="navbar navbar-expand-md">
  <div class="container-md px-0">
    <a class="navbar-brand d-flex align-items-end pb-0" [href]="getWWWUrl('')">
      <img src="/assets/images/WeShape-logo-dark.png" alt="WeShape Logo"
        class="d-inline-block align-text-top d-flex align-self-end">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" aria-controls="navbarText"
      aria-expanded="false" aria-label="Toggle navigation" data-bs-target="#navbarText">
      <img src="/assets/images/hamburger.png" alt="Hamburger" class="float-end">
    </button>
    <div class="collapse navbar-collapse mt-2" id="navbarText">
      <ul class="navbar-nav mb-2 mb-md-0 text-center py-5 py-md-0 flex-fill">
       
      </ul>
      <span class="navbar-text justify-content-md-end">
        <div class="container">
          <div class="row text-center pb-4 pb-md-0 d-md-flex align-items-md-center justify-content-md-center">
            <div class="col-md-6">
              <a class="nav-link" href="https://weshape.zendesk.com/hc/en-us/" target="_blank">FAQ</a>
            </div>
            <div class="col-md-6">
              <a href="https://my.weshape.com/login" class="btn text-center mt-3 mt-md-0" role="button">Sign In</a>
            </div>
          </div>
        </div>
      </span>
    </div>
  </div>
</nav>