import { asapScheduler } from 'rxjs';

/** VIDEOJS Components */
export const VideoJSComponent = window.videojs.getComponent('Component');
export const VideoJSButton = window.videojs.getComponent('Button');

export class PlaylistOverlay extends VideoJSComponent {
    constructor(player, options) {
        // It is important to invoke the superclass before anything else, 
        // to get all the features of components out of the box!
        super(player, options);
        this.addClass('custom-vjs-playlist-overlay');

        if(options.element) {
            this.createPlaylist(player,options);
        }
    }

    createPlaylist(player, options) {
        // Move Swiper Element into video player from template. Initialize swiper after it has been moved
        window.videojs.dom.appendContent(
            this.el(),
            options.element
        );

        // Require small delay for the above to complete
        asapScheduler.schedule(() => {
            options.playlistComponent.doSetSwiperConfig();
        });
    }
}

export class OpenScalingElement extends VideoJSComponent {
    constructor(player, options) {
        // It is important to invoke the superclass before anything else, 
        // to get all the features of components out of the box!
        super(player, options);

        if(options.element) {
            this.appendEl(player,options);
        }
    }

    appendEl(player, options) {
        // Add scaling element to the video player as the video can play without being fullscreen
        window.videojs.dom.appendContent(
            this.el(),
            options.element
        );
    }
}

export class BoxShadow extends VideoJSComponent {
    constructor(player, options) {
        // It is important to invoke the superclass before anything else, 
        // to get all the features of components out of the box!
        super(player, options);
        this.addClass('vjs-box-shadow');
    }
}