import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { SessionService, UsersService, PosthogService } from "../../../services";
import { ToastrService } from 'ngx-toastr';

declare const fbq:Function;

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {

  submitted = false;
  registerForm: FormGroup = this.fb.group({
    firstname: ['', [Validators.required, Validators.pattern(/^[A-ZÀ-ÿa-z0-9]+((\s)?((\'|\-|\.|\+|\@)?([A-ZÀ-ÿa-z0-9])+))*$/)]],
    lastname: ['', [Validators.required, Validators.pattern(/^[A-ZÀ-ÿa-z0-9]+((\s)?((\'|\-|\.|\+|\@)?([A-ZÀ-ÿa-z0-9])+))*$/)]],
    email: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")]]
  })

  @Output() updateUser: any = new EventEmitter;
  @Input() cssClasses;
  @Input() isFairyFunnel;

  constructor(private fb: FormBuilder,
              private userService: UsersService,
              private sessionService:SessionService,
              private toastr: ToastrService,
              private posthogService: PosthogService

  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.scrollIntoView();
  }

  loading = false;
  hideRegistration = false;

  get f(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  @ViewChild("registerContainer") registerContainer: ElementRef;

  registerUser(e) {
    this.submitted = true;
    e.preventDefault();
    if (this.registerForm.invalid) {
      return;
    } else {
      // Register User:
      this.loading = true;
      if(this.isFairyFunnel) {
        this.registerForm.value['quizAnswers'] = JSON.parse(window.localStorage.getItem(this.sessionService.quizItemKey));
      }
      this.userService.registerNewUser(this.registerForm.value, this.isFairyFunnel)
      .subscribe(
        {
          next: res => {
            this.hideRegistration = true;
            this.updateUser.emit(true);
            this.sessionService.weshapeToken = res.weshapeToken;
            localStorage.setItem("weshape_token", res.weshapeToken);
            this.posthogService.identifyUser(res.weshapeToken, {
              name: `${res.firstName} ${res.lastName}`,
              email: res.email
            });
          },
          error: error => {
            this.loading = false;
            this.toastr.error(error.error?.message, 'Error', { timeOut: 5000, });
          }
        });
    }

  }

  scrollIntoView() {
    this.registerContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  trimSpaces(formControl) {
    formControl.setValue(formControl.value.trim());
  }

}
