<div class="container-fluid float-end pt-lg-4 pb-lg-5">
  <div class="container-lg">
    <div class="row text-center">
      <div class="mt-5 mt-sm-5 my-lg-2 mt-lg-3 text-lg-start">
        <img src="/assets/images/WeShape-logo.png" alt="WeShape Logo" id="logo">
      </div>

      <div class="container disclaimer order-lg-1 col-lg-7 mb-5 mb-sm-5 mb-lg-3 text-lg-start">
        <b>DISCLAIMER</b>: This site provides general information for you to discuss with your physician. This site does
        not provide any professional advice or services. The ideas, procedures, and suggestions contained here are not a
        substitute for consulting with your physician, and should only be used as part of planning your development of
        an
        overall health and fitness plan with your physician. All matters regarding your health require medical
        supervision. Our <a [href]="getWWWUrl('terms')">TERMS OF SERVICE</a> limit our liability to you, and you are required to read
        and agree to them before using our site.
        <p class="weshape mt-3">&copy; WeShape 2022</p>
        <div class="d-flex justify-content-center justify-content-sm-center justify-content-lg-start resourses">
          <div class="d-flex justify-content-center">
            <a [href]="getWWWUrl('terms-and-conditions')" target="_blank">
              Terms
            </a>
          </div>
          <div class="pp-container px-1 mx-1 d-flex justify-content-center">
            <a [href]="getWWWUrl('privacy-policy')" target="_blank">
              Privacy Policy
            </a>
          </div>
          <div class="d-flex justify-content-center">
            <a [href]="getWWWUrl('do-not-sell')" target="_blank">
              Do Not Sell
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>