<div #recurlyElement class="recurly-elements">
  <label id="card-label" class="form-label">Card Number</label>
  <div #recurlyCardNum id="recurly-element-number"></div>
  <div [ngClass]="{'active': formSubmitted && !recurlyInputFields.number.valid}" class="invalid-feedback">{{ recurlyInputFields.number.message }}</div>
  <div class="wrap-month-year-cvv">
    <div class="recurly-month-year">
      <div class="input-wrap-pay-month">
        <label class="form-label">Expiration</label>
        <div #recurlyCardMonth id="recurly-element-month"></div>
      </div>
      <div class="input-wrap-pay-year">
        <div #recurlyCardYear id="recurly-element-year"></div>
      </div>
      <div class="flex-break"></div>
      <div [ngClass]="{'active': formSubmitted && (!recurlyInputFields.month.valid || !recurlyInputFields.year.valid)}" class="invalid-feedback">{{ recurlyInputFields.month.message }}</div>
    </div>
    <div class="recurly-month-year recurly-cvv">
      <div class="input-wrap-cvv">
        <label class="form-label">CVV</label>
        <div #recurlyCardCVV id="recurly-element-cvv"></div>
        <div [ngClass]="{'active': formSubmitted && !recurlyInputFields.cvv.valid}" class="invalid-feedback">{{ recurlyInputFields.cvv.message }}</div>
      </div>
    </div>
  </div>
</div>