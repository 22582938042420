import { ChangeDetectorRef, Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DomService, SitePageService, SessionService, EventService, UTMService, PosthogService } from 'src/app/services';
import { Title } from '@angular/platform-browser';

import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';

import { timer, Observable, of } from 'rxjs';
import { skip, skipWhile, filter, switchMap, take } from 'rxjs/operators';

import { LoaderComponent } from './shared';
import { environment } from 'src/environments/environment';

// import amplitude from 'amplitude-js';

declare const gtag: Function;
declare const fbq:Function;

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  window = this._document.defaultView;

  // Post Affiliate
  account_code: string;
  plan: string;

  constructor(
    private ref: ChangeDetectorRef,
    private sitePageService: SitePageService,
    private domService: DomService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    private sessionService: SessionService,
    private titleService: Title,
    private route: ActivatedRoute,
    private eventService: EventService,
    private utmService: UTMService,
    private posthogService: PosthogService,
    private overlay: Overlay
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      let pageItem = {
        "event": "appPageView",
        "hostname": window.location.hostname,
        "url": window.location.href,
        "urlPath": window.location.pathname,
      }
      window.scrollTo(0, 0);
      this.sessionService.addTagManagerEvent(pageItem);

      // Get query params from url
      if (!!event.url.split("?")[1]) {
        let queryParamsArr = event.url.split("?")[1].split('&');
        let queryParams = this.getParams(queryParamsArr)
        this.account_code = queryParams['account_code'] || null;
        this.plan = queryParams['plan'] || null;
        this.ref.detectChanges();
      }

      this.eventService.sendEvent(
        { event: '$pageview' }
      );
      
      // Default Page Title On A Route Change
      this.titleService.setTitle('WeShape');
    });
  }

  ngAfterViewInit() {
    this.ref.detach();
  }

  ngOnInit(): void {
    if (environment.thirdPartyTracking)
      this.initializeThirdPartyScripts();
    this.initializeTagManagerDataLayer();
    this.initializeLoader();
    this.initializeTrackingSession();
    // this.initializePostAffiliatePro();
  }

  ngOnDestroy(): void {
    this.posthogService.reset();
  }

  getParams(params) {
    let paramsReturn = {};
    params.forEach(item => {
      let temp = item.split('=');
      paramsReturn[temp[0]] = temp[1] || undefined;
    });
    return paramsReturn;
  }

  initializeThirdPartyScripts() {
    this.sitePageService.getThirdPartyScripts().subscribe((response) => {
      response.scripts.forEach(element => {
        this.sitePageService.addScript(element.attributes, element.removeOnExit);
      });
    });
  }

  initializeTrackingSession() {
    this.eventService.eventAPIKeys$.pipe(
      switchMap((details) => {
        if (!details) {
          this.eventService.initEvents();
          return of(false);
        }
        return this.eventService.eventAPIKeys$;
      }),
      skipWhile((data) => !data),
      take(1)
    ).subscribe((details) => {
      if(details['posthogToken'] && details['posthogHost']) {
        this.posthogService.initializePostHog(details['posthogHost'], details['posthogToken']);
      }
    });
  }

  initializePostAffiliatePro() {
    this.route.queryParams.subscribe((queryParamMap) => {
      this.account_code = queryParamMap['account_code'];
      this.plan = queryParamMap['plan'];

      // Required to detect above boolean
      this.ref.detectChanges();
    });
  }

  initializeTagManagerDataLayer() {
    this.window.dataLayer = this.window.dataLayer || [];
  }

  initializeLoader() {
    this.domService.loadingModal$.pipe(skipWhile(item => !item)).subscribe(item => {
      if (item?.display) {
        this.dialog.open(LoaderComponent, {
          data: {
            message: item.message,
          },
          disableClose: true,
          hasBackdrop: true,
          panelClass: 'loading-modal-container',
          scrollStrategy: this.overlay.scrollStrategies.noop()
        });
      }
      else {
        this.dialog.closeAll()
        this.ref.detectChanges();
      }
    });
  }
}
