import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { SitePageService } from 'src/app/services';

declare let processPostAffiliateRecurly: any;

@Component({
  selector: 'app-postaffiliate',
  templateUrl: './postaffiliate.component.html',
  styleUrls: ['./postaffiliate.component.scss']
})
export class PostaffiliateComponent implements OnInit {
  @Input() set account_code(value) {
    this._account_code = value;
    this.checkDoProcessing();
  }

  @Input() set plan(value: string) {
    this._plan = value;
    this.checkDoProcessing();

  }

  _account_code: string = undefined;
  _plan: string = undefined

  constructor(
    private sitePageService: SitePageService
  ) { }

  ngOnInit(): void { }

  checkDoProcessing() {
    if (this.doProcessPostAffiliate) {
      let postAffiliateObj = {
        "type": "postAffiliatePro",
        "removeOnExit": true,
        "attributes": {
          "src": "https://weshape.postaffiliatepro.com/scripts/trackjs.js",
          "id": "pap_x2s6df8d"
        }
      }

      // Insert required Post Affiliate Pro script
      this.sitePageService.addScript(postAffiliateObj.attributes);
      processPostAffiliateRecurly(this._account_code, this._plan);
    }
  }

  get doProcessPostAffiliate() {
    return !!this._account_code && !!this._plan;
  }

}
