import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment, UrlMatcher } from '@angular/router';


/** Components */
import { QuizMainComponent } from './pages/quiz/quiz-main/quiz-main.component';
import { GenericURLHandlerComponent } from './pages/generic-urlhandler/generic-urlhandler.component';
import { QuizVersionComponent } from './pages/quiz/quiz-version/quiz-version.component';
import { RedirectComponent } from './pages/main-site/redirect-component/redirect.component';
import { QuizBypassPagesComponent } from './pages/quiz/quiz-bypass-pages/quiz-bypass-pages.component';
import { ChallengeComponent } from './pages/challenge/challenge.component';
import { ChallengeReferralComponent } from "./pages/challenge/challenge-referral/challenge-referral.component";
import { ChallengePromoConfirmationComponent } from './pages/challenge/challenge-promo-confirmation/challenge-promo-confirmation.component';
import { DemoV1Component } from './pages/workout-demo/demo-v1/demo-v1.component';

import { PaymentRedirectPurchaseComponent, VerifyEmailComponent, NocctrialThankyouConfirmationComponent } from './shared';

/** Routing Guard */
import { WeshapeTokenGuard } from './guards';

/** Resolvers */
import { WeshapeTokenUserResolver, SalesWeshapeTokenUserPageResolver, FairyQuizUserResolver, FairyPostSalesPageResolver } from './resolver';

/** Matchers */
export function promoPages(url: UrlSegment[]) {
  let promoCheck = url[0].path === 'promo' ? ({ consumed: url }) : null;
  return promoCheck;
}

export function postQuizPages(url: UrlSegment[]) {
  let postQuizCheck = url[0].path === 'post-quiz' ? ({ consumed: url }) : null;
  return postQuizCheck;
}

export function quizByPass(url: UrlSegment[]) {
  return url[0].path === 'quizpage' ? ({ consumed: url }) : null;
}

export function quizPages(url: UrlSegment[]) {
  if (url[0].path === 'quiz') {
    return {
      consumed: url
    };
  }
  return null;
}

/**
 * @description
 * Url Matcher that redirects previous challenge signup urls to main challenge route
 */
export function challengePagesRedirect(url: UrlSegment[]) {
  let fullUrl = '';
  const redirectArray = ['/l/fgc/v001', '/l/fgc/v002', '/l/fgc/v003', '/l/fgc/v004', '/l/fgc/v005', '/l/fgc/v007', '/l/fgc/v008', '/l/fgc/v009', '/l/fgc/v010', '/l/fgc/v011'];
  // const redirectArray = ['/l/fgc/v0011', '/l/fgc/v0012', '/l/fgc/v0013', '/l/fgc/v0014', '/l/fgc/v0015'];

  // Combine segments for comparison
  url.forEach(segment => fullUrl = `${fullUrl}/${segment.path}`);
  let foundRedirectChallengeURL = redirectArray.some(url => fullUrl.includes(url));
  return foundRedirectChallengeURL ? ({ consumed: url }) : null;
}

/**
 * @description
 * Matches url against /l/fgc/
 *
 * @usageNotes
 *
 * If url matches /l/fgc/ it will return the last segment as a viewOption into the Challenge Component.
 *
 * Template will be rendered based on version
 */
export function challengePages(url: UrlSegment[]) {
  let fullUrl = '';
  // Combine segments for comparison
  url.forEach(segment => fullUrl = `${fullUrl}/${segment.path}`);
  let foundChallengeURL = fullUrl.includes('/l/fgc/');

  // viewOption ex v004
  let viewOption = (url[url.length - 1].path).toLowerCase();
  return foundChallengeURL ? ({
    consumed: url,
    posParams: {
      viewOption: new UrlSegment(viewOption, {})
    }
  }) : null;
}


/**
 * @description
 * Matches url against masterclass/[TYPE]/referral
 *
 * Template will be rendered based on version
 */
export function masterClassReferrals(url: UrlSegment[]) {
  let regex = /masterclass\/(.*?)\/referral/;
  let fullUrl = '';
  url.forEach(segment => fullUrl = `${fullUrl}/${segment.path}`);
  if (regex.test(fullUrl)) {
    return {
      consumed: url
    };
  }
  return null;
}

const routes: Routes = [
  { path: '', component: RedirectComponent },

  { path: 'challenge', component: ChallengeComponent },
  { path: 'challenge/referral', component: ChallengeReferralComponent },
  { path: 'challenge/thank-you', component: ChallengePromoConfirmationComponent },
  { matcher: challengePagesRedirect, redirectTo: 'challenge' },
  { matcher: challengePages, component: ChallengeComponent },

  { path: 'verify-email', component: VerifyEmailComponent },
  { path: 'verify-email-carecall', component: VerifyEmailComponent },
  { path: 'nocctrial/thank-you', component: NocctrialThankyouConfirmationComponent },
  { path: 'l/', component: GenericURLHandlerComponent },
  { path: 'member', redirectTo: 'quiz', pathMatch: 'full' },
  { path: 'membership', redirectTo: 'quiz', pathMatch: 'full' },
  { path: 'membership/quiz-1', redirectTo: 'quiz', pathMatch: 'full' },
  { path: 'qz/:slug', component: QuizVersionComponent },
  { path: 'quiz', component: QuizMainComponent, resolve: { userData: WeshapeTokenUserResolver } },
  { path: 'membermobile', component: QuizMainComponent, resolve: { userData: WeshapeTokenUserResolver }, data: { isMobile: true } },
  { matcher: quizByPass, component: QuizBypassPagesComponent },
  { path: 'promo/:slug/:slug', component: GenericURLHandlerComponent, resolve: { userData: WeshapeTokenUserResolver } },
  { path: 'post-quiz/demoworkout', component: DemoV1Component, canActivate: [WeshapeTokenGuard], resolve: { userData: SalesWeshapeTokenUserPageResolver } },
  { path: 'payment-redirect', component: PaymentRedirectPurchaseComponent },
  { path: 'fairy-quiz', component: QuizMainComponent, resolve: { userData: FairyQuizUserResolver } },
  { path: 'fairy-post-quiz/:slug', component: GenericURLHandlerComponent, resolve: { userData: FairyPostSalesPageResolver } },
  { matcher: postQuizPages, component: GenericURLHandlerComponent, canActivate: [WeshapeTokenGuard], resolve: { userData: SalesWeshapeTokenUserPageResolver } },
  { matcher: quizPages, redirectTo: 'quiz' },
  { matcher: promoPages, component: GenericURLHandlerComponent, resolve: { userData: WeshapeTokenUserResolver } },
  { matcher: masterClassReferrals, component: GenericURLHandlerComponent, resolve: { userData: WeshapeTokenUserResolver } },

  { path: '**', component: GenericURLHandlerComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
