import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, shareReplay, map } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ChallengeService {

  signupFormBS = new BehaviorSubject<any>(null);
  signupForm$ = this.signupFormBS.asObservable();

  smsSignupFormBS = new BehaviorSubject<any>(null);
  smsSignupForm$ = this.smsSignupFormBS.asObservable();

  // Signup currently in process
  processingStateBS = new BehaviorSubject<{
    type: string;
    submitted: boolean;
    processing: boolean;
    message?: string;
  }>({ type: '', submitted: false, processing: false, message: '' });
  processingState$ = this.processingStateBS.asObservable();

  constructor(private http: HttpClient) { }

    /* API CALLS */
    getChallenge<T>(): Observable<any> {
      let apiEndpoint = `${environment.apiUrl}/challenge/init`;
      return this.http
        .get<any>(apiEndpoint)
        .pipe(catchError((error) => of({ error: error.message })));
    }

    getChallengeWithToken<T>(token): Observable<any> {
      let apiEndpoint = `${environment.apiUrl}/challenge/init/`+token;
      return this.http
        .get<any>(apiEndpoint)
        .pipe(catchError((error) => of({ error: error.message })));
    }

    updateReferralForm<T>(data): Observable<any> {
      let apiEndpoint = `${environment.apiUrl}/challenge/referral`;
      const headers = new HttpHeaders().set(
        'Content-Type',
        'application/json; charset=utf-8'
      );
      return this.http
        .post<any>(apiEndpoint, data, {
          headers,
          responseType: 'text' as 'json'
        })
        .pipe(map((data) => ({ message: data })));
    }

    updateFullSignUpForm<T>(data): Observable<any> {
      let apiEndpoint = `${environment.apiUrl}/challenge/full-signup`;
      const headers = new HttpHeaders().set(
        'Content-Type',
        'application/json; charset=utf-8'
      );
      return this.http
        .post<any>(apiEndpoint, data)
        .pipe(map((data) => ({ message: data })));
    }

    updateSignUpForm<T>(data): Observable<any> {
      let apiEndpoint = `${environment.apiUrl}/challenge/signup`;
      const headers = new HttpHeaders().set(
        'Content-Type',
        'application/json; charset=utf-8'
      );
      return this.http
        .post<any>(apiEndpoint, data)
    }

    updateSMSSignUpForm<T>(data): Observable<any> {
      let apiEndpoint = `${environment.apiUrl}/challenge/sms-opt-in`;
      const headers = new HttpHeaders().set(
        'Content-Type',
        'application/json; charset=utf-8'
      );
      return this.http
        .post<any>(apiEndpoint, data)
    }

    signupCompleteForm<T>(data): Observable<any> {
      let apiEndpoint = `${environment.apiUrl}/challenge/signup-complete`;
      let params = new HttpParams();
      params = params.append('weshapeToken', data);
      return this.http
        .post<any>(apiEndpoint, params);
    }

    getWistiaAPIKey<T>(): Observable<any> {
      // Return temporary API Key required for wistia video upload
      let apiEndpoint = `${environment.apiUrl}/challenge-wistia`;
      return this.http.get<any>(apiEndpoint);
    }
}
