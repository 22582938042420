import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/* @angular Material */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';

/* Components */
import { BaseComponent } from './base.component';
import { AppComponent } from './app.component';

/* Modules*/
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

/* Inteceptors */
import { RequestsInterceptor } from './interceptor';

/* RXJS */
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

// Create factory for APP_INITIALIZER to get utm id
import { UTMService, SessionService } from 'src/app/services/';
export function initUTMParams(utmService: UTMService, sessionService: SessionService) {
  return () => {
    return utmService.initUTMItems().pipe(
      tap(value => {
        localStorage.setItem("utm_id", value.res);
        sessionService.setCookie('utm_id', value.res, 180);
      }),
      catchError(() => {
        return of(null);
      })
    );
  };
}
declare global {
  interface Window {
    recurly?: any;
    dataLayer: any[]; // Tag Manager
    videojs: any;
    ApplePaySession: any;
    wsTimerSettings: any; // external script settings
  }
}

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    PagesModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot()
  ],
  providers: [
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestsInterceptor,
        multi: true
      },
      {
        provide: APP_INITIALIZER,
        useFactory: initUTMParams,
        deps: [UTMService, SessionService],
        multi: true
      },
      CookieService
    ]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
