import { Directive, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[domChanged]'
})
export class DomChangedDirective implements OnDestroy {
  private changes: MutationObserver;
  @Output() public domChanged = new EventEmitter();

  constructor(private elementRef: ElementRef) {
    const element = this.elementRef.nativeElement;
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => this.domChanged.emit(mutation));
    }
    );

    this.changes.observe(element, {
      attributes: true,
      childList: true,
      characterData: true
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

}
