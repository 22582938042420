<dialog id="questionDescriptionPopup" class="dialogBox">
  <div class="dialogBox-header">
    <img src="/assets/images/quiz/quiz_question.png" alt="question Button">
  </div>
  <div class="dialogBox-body">
    <!-- An element that displays a survey element's 'popupdescription' property value-->
    <div id="questionDescriptionText"></div>
  </div>
  <div id="questionDescriptionPopupClose" class="dialogBox-footer">
    <button type="button"
            class="dialogBox-close">
      Close
    </button>
  </div>
</dialog>
<survey id='surveyContainer'  [model]="surveyModel"></survey>
