import { Component, Input, OnInit, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { BillingService, DomService, EventService, SessionService } from "../../../services";
import { Router } from "@angular/router";
import { interval, Subscription } from "rxjs";
import { finalize } from "rxjs/operators";

import { ViewportScroller } from "@angular/common";
declare const fbq:Function;

@Component({
  selector: 'app-upsell',
  templateUrl: './upsell.component.html',
  styleUrls: ['./upsell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpsellComponent implements OnInit {

  @Input() planOptions: any;
  @Input() pageId: string = '';
  planType: string = '';
  checkbox1 = false;
  dateNow = new Date();
  dDay: any;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  minutes: any = '00';
  seconds: any = '00';
  listener;
  redtrackID;

  private subscription: Subscription;

  constructor(
    private billingService: BillingService,
    private route: Router,
    private scroller: ViewportScroller,
    private domService: DomService,
    private eventService: EventService,
    private sessionService: SessionService,
    ) { }

  @HostListener('window:scroll', ['$event'])

  onScroll(e) {
    let stickyDiv = e.target.querySelector('.sticky-div');
    let currStickyPos = stickyDiv.offsetTop + window.pageYOffset;
    if (window.pageYOffset > stickyDiv.offsetTop) {
      stickyDiv.style.position = "fixed";
      stickyDiv.style.top = "0px";
      stickyDiv.style.marginLeft = "-0.5em";
    } else {
      stickyDiv.style.position = "relative";
      stickyDiv.style.top = "initial";
      stickyDiv.style.marginLeft = "0";
    }
  }

  ngOnInit(): void {
    this.dDay = new Date(this.dateNow.setSeconds(this.dateNow.getSeconds() + 480));
    this.subscription = interval(1000)
      .subscribe(x => { this.getTimeDifference(); });
    if (this.planOptions) {
      this.planType = this.planOptions.productId == 'y0' ? 'Yearly' : 'Quarterly';
    }
    this.getRedTrackCookie();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      document.querySelectorAll('.upgradebtn')
        .forEach((btn, index) =>
          btn.addEventListener("click", () => {
            const element = document.getElementById("upsellCheckout");
            element.scrollIntoView({ behavior: "smooth" });
          }));
    }, 2000)
  }

  private getTimeDifference() {
    let timeDifference = this.dDay.getTime() - new Date().getTime();
    if (timeDifference > 0) {
      this.allocateTimeUnits(timeDifference)
    } else {
      this.routeToVerify();
      this.subscription.unsubscribe();
    }
  }

  private allocateTimeUnits(timeDifference) {
    this.seconds = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutes = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    if (document.getElementById('time-upsell')) {
      document.getElementById('time-upsell').innerText = "0" + this.minutes + " : " + this.seconds;
    }
  }

  upgradeTo() {
    this.domService.loadingModalBS.next({ display: true, message: "Processing" });
    this.billingService.submitUpsell(this.pageId, this.redtrackID).pipe(
      finalize(() => this.domService.loadingModalBS.next({ display: false }))
    ).subscribe({
      next: res => {
        // Successful response, redirect to next page
        if (res && res.res) {
          this.sendEvents('upsell decision',{ did_upgrade: 'true' });

          // Retrieve any query params from response url and append to redirect url later
          let queryParams = (res.res).split("?");
          let redirectURL = `${queryParams[0]}` + '-carecall';

          // Append query params back to the redirect url
          if(!!queryParams[1]) {
            redirectURL = `${redirectURL}?${queryParams[1]}`;
          }
          this.updateMetaPixelEvent(this.planOptions.price);
          this.route.navigateByUrl(redirectURL, { state: { coachingCall: true } });
          return;
        }
      },
      error: err => {
        // Append error message to form and focus on message
        console.log(err);
      }
    });
  }

  sendEvents(event, eventProperty = {}) {
    this.eventService.sendEvent({
        event: event,
        event_properties: eventProperty
    });
  }

  routeToVerify(value = '') {
    if(value === 'checkout-no')
    {
      this.sendEvents('upsell decision', { did_upgrade: 'false'});
    }
    this.route.navigate(['/verify-email'])
  }

  getRedTrackCookie() {
    this.redtrackID = this.sessionService.getCookie("rtkclickid-store");
  }

  updateMetaPixelEvent(value) {
    fbq('track', 'Purchase', {
      value: value,
      currency: 'USD'
       });
  }

}
