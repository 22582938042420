import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChallengeComponent } from '../challenge.component';
import { Title } from "@angular/platform-browser";
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
  FormArray
} from '@angular/forms';

import { take, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { SitePageService, ChallengeService, SessionService, EventService } from 'src/app/services';

@Component({
  selector: 'app-challenge-referral',
  templateUrl: './challenge-referral.component.html',
  styleUrls: ['./challenge-referral.component.scss']
})
export class ChallengeReferralComponent implements OnInit {


  first_name: string = '';
  email: string = '';
  list: any = [];
  disabled: boolean = true;
  showConfimation: boolean = false;
  checkImage: string = '/assets/images/Confetti.svg';
  verifiedImage: string = '/assets/images/verify-account.svg';
  submitted = false;
  errorContent: string = '';
  existingMember: boolean = false;


  // Form Information
  numStartReferral: number = 3; // Initial number of inputs on page
  numMaxReferral: number = 10; // Max number of inputs allowed
  currentReferralCount: number = this.numStartReferral; // Initial number of inputs on page
  maxInviteReached: boolean = false;

  referralForm: FormGroup;
  referralInputs = { firstName: null, email: null };
  referralInputRegex = {
    'name': /^[A-ZÀ-ÿa-z0-9]+((\s)?((\'|\-|\.|\+|\@)?([A-ZÀ-ÿa-z0-9])+))*$/,
    'email': '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'
  };

  @ViewChild('referralEl') referralEl?: ElementRef;

  constructor(
    private challengeService: ChallengeService,
    private session: SessionService, 
    private titleService: Title, 
    private fb: FormBuilder, 
    private route: ActivatedRoute,
    private sitePageService: SitePageService,
    private eventService: EventService
    ) {
    this.existingMember = this.route.snapshot.queryParams['existing'] === 'true';
  }

  ngOnInit(): void {
    this.titleService.setTitle('Challenge confirmation | WeShape');
    if (!this.session.getSessionParamater(ChallengeComponent.REFEREE_FIRST_NAME)) {
      const urlParams = new URLSearchParams(window.location.search);
      const fn = urlParams.get('fn');
      const em = decodeURIComponent(urlParams.get('email'));

      this.session.addSessionParamater(ChallengeComponent.REFEREE_FIRST_NAME, fn);
      this.session.addSessionParamater(ChallengeComponent.REFEREE_EMAIL, em);
    }
    this.createReferralForm();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.referralEl.nativeElement.scrollIntoView();
    window.scrollTo(0,0);
  }

  createReferralForm() {
    // Create the minimum number of inputs to display first
    let tempForm: Array<{ firstName: string, email: string }> = [];
    for (let i = 0; i < this.numStartReferral; i++) {
      tempForm.push({ ...this.referralInputs });
    }

    // Build Initial Reactive Form Data
    this.referralForm = this.fb.group({
      referralGroup: this.fb.array(tempForm.map(
        (item, index) => this.fb.group({
          firstName: [''],
          email: ['']
        })
      ))
    });

    this.setValidators();
  }

  addReferralInputs() {
    // Add More Input Groups each click. 
    // Only insert up to max allowed
    if (this.currentReferralCount < this.numMaxReferral) {
      let index = this.referralGroup.length;
      this.referralGroup.push(this.fb.group({
        firstName: '',
        email: ''
      }))
      this.currentReferralCount = this.currentReferralCount + 1;
      if (this.currentReferralCount === this.numMaxReferral) {
        this.maxInviteReached = true;
      }
      this.setValidators();
    }
  }

  setValidators() {
    this.referralGroup.controls.forEach((formGroup, index) => {
      // Set Validators on Main Input Group
      formGroup.setValidators(this.inputValidator());

      // Clear all validators first
      formGroup.get('firstName').clearValidators();
      formGroup.get('email').clearValidators();

      // Set Custom Validators
      formGroup.get('firstName').setValidators([Validators.pattern(this.referralInputRegex.name)]);
      formGroup.get('email').setValidators([Validators.pattern(this.referralInputRegex.email)]);

      // Run Validation
      formGroup.get('firstName').updateValueAndValidity();
      formGroup.get('email').updateValueAndValidity();
    });
  }

  formatFormData(formData) {
    return formData.map(referral => ({
      firstName: referral.firstName,
      email: referral.email
    }));
  }

  inputValidator() {
    // Name and Email must be populated if either is filled
    const validator = (formArray: FormArray) => {
      let controls = Object.keys(formArray.controls);

      // Check if Both Inputs are Empty
      let emptyInputsCheck = controls.every(item => formArray.controls[item].value === '');

      // At least 1 input entered, check to make sure other inputs are set
      let validInputsCheck = true;

      if (!emptyInputsCheck) {
        controls.forEach(item => {
          if (item === 'firstName' && formArray.controls['email'].value === '') {
            formArray.controls['email'].errors = "Email Cannot Be Empty";
          } else if (item === 'email' && formArray.controls['firstName'].value === '') {
            formArray.controls['firstName'].errors = "Name Cannot Be Empty";
          }
        });

        validInputsCheck = controls.every(item => formArray.controls[item].valid && formArray.controls[item].value != '');
      } else {
        // Reset Errors as both inputs are now empty
        formArray.controls['email'].errors = null;
        formArray.controls['firstName'].errors = null;
      }

      return (emptyInputsCheck || validInputsCheck) ? null : { required: true };
    };
    return validator;
  }

  submitForm(e) {
    this.submitted = true;
    e.preventDefault();
    if (this.referralForm.invalid) {
      return;
    } else {
      let formData = this.referralGroup.value.filter(({ firstName, email }) => firstName != '' && email != '');
      if (formData.length) {
        // Build List Of Referrals to Send 
        this.list = this.formatFormData(formData);
        let data = {
          referralList: this.list,
          refFirstName: this.session.getSessionParamater(ChallengeComponent.REFEREE_FIRST_NAME),
          refLastName: this.session.getSessionParamater(ChallengeComponent.REFEREE_LAST_NAME),
          refEmail: this.session.getSessionParamater(ChallengeComponent.REFEREE_EMAIL)
        }
        this.challengeService.updateReferralForm(data).subscribe((res) => {
          this.eventService.sendEvent(
            { event: 'prospect_sent_application_referral' }, 
            data.refEmail
          );
          this.showConfimation = true;
        })
      }
    }
  }

  getWWWUrl(url) {
    return this.sitePageService.getWWWUrl(url);
  }

  trimSpaces(formControl){
    formControl.setValue(formControl.value.trim());
  }

  get referralGroup(): FormArray {
    return this.referralForm.get('referralGroup') as FormArray;
  }
}
