import { Component, OnInit, Input, ViewChild, ElementRef, Inject, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-apple-pay',
  templateUrl: './apple-pay.component.html',
  styleUrls: ['./apple-pay.component.scss']
})
export class ApplePayComponent implements OnInit {
  @Input() client;
  @Input() productInfo;
  @Input() termsAccepted: boolean = false;
  @Input() token: string;
  @Input() enabled: boolean;

  @Output() sendApplePayAuth = new EventEmitter();
  @Output() paymentError = new EventEmitter();
  @Output() displayTerms = new EventEmitter();
  @Output() applePayInit = new EventEmitter();

  @ViewChild("applePay") applePayBtn: ElementRef;

  window = this._document.defaultView;
  applePay: any;
  applePayAvailable: boolean = true;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (!this.window.ApplePaySession || !this.enabled) {
      this.applePayAvailable = false;
      this.applePayInit.emit(true);
      return;
    }
    this.initializeApplePay();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.applePayAvailable) return

    // Create New Apple Pay Instance with new selected product
    if (
      changes.productInfo && 
      !changes.productInfo.firstChange &&
      changes.productInfo.currentValue?.plan != changes.productInfo.previousValue?.plan
    ) {

      if(changes.productInfo.currentValue.plan)
        this.productInfo = changes.productInfo.currentValue;

      this.initializeApplePay();
    }
    if(changes.termsAccepted && changes.termsAccepted.currentValue) {
      this.applePay.begin();
    }
  }

  initializeApplePay() {
    // First remove any instances of apple pay and event listener to start a new instance
    this.applePay = null;

    try {
      this.applePay = this.client.ApplePay(this.productItem);
    } catch (e) {
      console.error(`Apple Pay Initialize Error`);
      console.error(e);
      this.applePayAvailable = false;
      this.applePayInit.emit(true);
    } finally {
      if(!this.applePayAvailable) return

      this.applePay.ready(() => {
        this.applePayBtn.nativeElement.classList.remove('apple-pay-button-hidden');
        this.applePayInit.emit(true);
        this.setApplePayEventHanders();
      })
    }
  }

  setApplePayEventHanders() {
    let that = this;
    this.applePay.on('paymentAuthorized', (msg) => {});

    this.applePay.on('error', (err) => {
      console.error('Apple Pay error', err);
      that.paymentError.emit({ 
        type: 'applepay',
        message: err
      })
    });

    this.applePay.on('token', (res) => {
      that.sendApplePayAuth.emit({
        type: 'applepay',
        token: res.id
      });
    });
  }

  termsClickHandler() {
    this.displayTerms.emit("applepay");
  }

  get productItem() {
    return {
      country: 'US',
      currency: 'USD',
      label: this.productInfo.description,
      total: this.productInfo.price,
      braintree: {
        clientAuthorization: this.token,
        displayName: 'WeShape'
      }
    }
  }
}