import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-challenge-v001',
  templateUrl: './challenge-v001.component.html',
  styleUrls: ['./challenge-v001.component.scss']
})
export class ChallengeV001Component implements OnInit {

  @Input() timer;

  constructor() { }

  ngOnInit(): void {
  }

}
