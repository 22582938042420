import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingService, MaropostService, SitePageService, UTMService } from 'src/app/services/';
import { Title } from '@angular/platform-browser';

import { workoutCodeDemo, movementList } from 'src/app/shared/components/video-player/workout';

@Component({
  selector: 'workout-demo-v1',
  templateUrl: './demo-v1.component.html',
  styleUrls: ['./demo-v1.component.scss']
})
export class DemoV1Component implements OnInit {

  constructor(
    public route: ActivatedRoute,
    private sitePageService: SitePageService,
    private titleService: Title,
    private router: Router,
    private utmService: UTMService,
    private maropostService: MaropostService,
    public billingService: BillingService
  ) { }

  @ViewChild('salesInformation') salesInfo: ElementRef;

  workoutDemoItem = workoutCodeDemo;
  movementList = movementList;
  userInfo;

  planOptions:any;
  pageType: string;
  pageId: string;
  newUser: boolean = false;

  ngOnInit(): void {
    // User data comes from resolver for post quiz pages
    if ('userData' in this.route.snapshot.data) {
      let data = this.route.snapshot.data['userData'];
      // Route checked with resolver
      if (data) {
        this.userInfo = data;
      }
    }

    // Get Demo page information
    this.sitePageService.loadSitePage(location.pathname).subscribe((layout) => {
      if(layout.billing)
        this.planOptions = layout.billing;

      this.pageId = layout.pageId;
      this.pageType = layout.pageType;
      this.billingService.planSelected = null;
      this.setTitle();
    });
  }

  setTitle() {
    let displayName = this.userInfo.profile.firstName ? this.userInfo.profile.firstName : '';
    const title = `${displayName}'s Workout Demo | Weshape`;
    this.titleService.setTitle(title);
  }

  showInformation() {
    // display sales information
    this.salesInfo.nativeElement.classList.toggle('active');
  }

  videoCompletion(event) {
    this.utmService.updateUTM(event).subscribe({
      next: res => console.log(res),
      error: error => console.error(error)
    });
  }

  sendTag($event){
    this.maropostService.addTag($event).subscribe({
      next: res => console.log(res),
      error: error => console.error(error)
    })
  }
}
