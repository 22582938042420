import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { SessionService } from '../services';


const REQUIRE_USERTOKEN = ['/profile', '/billing', '/quiz', '/site']

const SKIP_CUSTOM_HEADERS = ['/dynamic-assets']

@Injectable()
export class RequestsInterceptor implements HttpInterceptor {

  constructor(private sessionService: SessionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get wid_token from token to send as part of the header if set
    let wid_token = localStorage.getItem('wid_token') || undefined;
    let utm_id = localStorage.getItem("utm_id") || undefined;

    // Check to see if the request contains a required user token in its header
    let userTokenReq = REQUIRE_USERTOKEN.some(endpoint => ((request.url).toLowerCase()).includes(endpoint));
    // Check to see if custom headers are required to be added as part of the request
    let customHeaderReq = SKIP_CUSTOM_HEADERS.some(endpoint => ((request.url).toLowerCase()).includes(endpoint));

    if(!customHeaderReq) {
      // Set the initial utm_id to send in all header requests if it currently exists
      if (utm_id)
        request = this.updateHeaders(request, {utm_id});

      if (userTokenReq && wid_token)
        request = this.updateHeaders(request, {wid_token});
    }

    return next.handle(request).pipe (
      map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && (request.url).toLowerCase().includes('/profile')) {
            let res = event.body;
            if(res && res.id){
              localStorage.setItem('wid_token', res.id);
            }
          }
          return event;
        })
      );
  }

  updateHeaders(request, item) {
    return request.clone({
      setHeaders: item,
    });
  }
}
