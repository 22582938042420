import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { environment } from "src/environments/environment";
@Component({
  selector: 'app-nocctrial-thankyou-confirmation',
  templateUrl: './nocctrial-thankyou-confirmation.component.html',
  styleUrls: ['./nocctrial-thankyou-confirmation.component.scss']
})
export class NocctrialThankyouConfirmationComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Thank You | Weshape');
  }

  returnToProduct() {
    this.document.location.href = `${environment.productUrl}`;
  }

}
