import { Injectable } from '@angular/core';
const CryptoJS = require('crypto-js');

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  key;

  constructor() {
    const passphrase = ''; // Passphrase should be retrieved from somewhere
    this.key = CryptoJS.enc.Base64.parse(passphrase);
  }

  getEncodedKey(data: string, start: number, end: number) {
    // Extract Key from decoded string with given indices
    return data.substring(start, end);
  }

  getEncryptedString(data: string, passphrase: string) {
    this.key = CryptoJS.enc.Base64.parse(passphrase);
    let srcs = CryptoJS.enc.Utf8.parse(data);
    let encrypted = CryptoJS.AES.encrypt(srcs, this.key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    let str = encrypted.toString();
    str = encodeURI(btoa(str));
    return str;
  }

  doDecryptString(data: string, start: number, length: number) {
    let encryptString = decodeURIComponent(data);
    try {
      let keyForCryptoJS = this.getEncodedKey(encryptString, start, (start+length));
      let parsedCryptoKey = CryptoJS.enc.Base64.parse(keyForCryptoJS)
      encryptString = encryptString.replace(keyForCryptoJS, '');

      // Decode encrypted string with retrieved encode key
      let decodeBase64 = CryptoJS.enc.Base64.parse(encryptString);

      let decryptedData = CryptoJS.AES.decrypt({ ciphertext: decodeBase64 }, parsedCryptoKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
      let decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);

      return decryptedText;

    }
    catch (err) {
      console.log(err);
    }

  }

  generateSalt(length) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
      counter += 1;
    }
    return result;
  }
}
