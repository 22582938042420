import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  trigger,
  state,
  transition,
  style,
  animate
} from '@angular/animations';

import { interval } from 'rxjs';

@Component({
  selector: 'app-scaling-screen',
  templateUrl: './scaling-screen.component.html',
  styleUrls: ['./scaling-screen.component.scss'],
  animations: [
    trigger('slideInOutAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('500ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate(
          '500ms ease-in',
          style({ transform: 'translateY(-100%)' })
        )
      ])
    ]),
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.25s ease-out', style({ opacity: 0.8 }))
      ]),
      transition(':leave', [
        style({ opacity: 0.8 }),
        animate('.25s ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('inLowAnimation', [
      state(
        'initial',
        style({ opacity: 1, transform: 'translateX(0px)' })
      ),
      state(
        'hidden',
        style({ opacity: 0.1, transform: 'translateX(-2000px)' })
      ),
      transition('initial=>hidden', animate('500ms')),
      transition('hidden=>initial', animate('500ms'))
    ])
  ]
})
export class ScalingScreenComponent implements OnInit {
  @Input() scalingMovementLookup;
  @Input() filteredMovement;
  @Input() index;
  @Input() scalingScreenTimer: number; // In Seconds.  How long the scaling screen stays up


  scalingUpToggle: boolean = false;
  scalingDownToggle: boolean = false;

  /* LEVELING PROMPT */
  changePrompt: boolean = true;
  intensityPrompt: boolean = false;
  btnDisabled: boolean;
  timerPrompt: any;
  scalingObject: any = {};
  openScalingTimer: any;
  // resetScalingTimer: any;
  spinnerTimer: any;
  spinnerTimerValue: number = 0;

  scalingTimer$;

  showOpenScaling: boolean = false;
  scaleDown: string = 'scaling-down-arrow.svg';
  scaleUp: string = 'scaling-up-arrow.svg';
  showHighestAlert: boolean = false;
  showLowestAlert: boolean = false;
  dontAskAgain_movement: any = [];
  dontAskAgain: boolean = false;
  btnLvlClicked: string = '';

  @ViewChild('openScalingScreen') openScalingScreenEl: ElementRef;

  // Video transition
  currentState: string = 'initial';

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.scalingMovementLookup?.currentValue) {
      this.getLevelingDetails();
    }
    if (changes?.filteredMovement?.currentValue) {
      this.getMovementListDetails();
    }
  }


  // get leveling details to update button on intensity prompt prior the video plays
  getLevelingDetails() {
    let updateScaling = false;
    updateScaling = !(
      this.scalingMovementLookup.startsWith('RW') ||
      this.scalingMovementLookup.startsWith('NR') ||
      this.scalingMovementLookup.startsWith('BI')
    );

    if (this.scalingMovementLookup != '' && updateScaling) {
      this.scalingObject.index = this.index;
      this.scalingObject.movement = this.scalingMovementLookup; //Get the movement for prompted workout
      this.scalingObject.lValue = this.scalingObject.movement.slice(6, 9);
      this.scalingObject.lLvl = parseInt(this.scalingObject.lValue.slice(1, 3));
      this.scalingObject.nValue = this.scalingObject.movement.slice(13, 16);
    }
  }

  getMovementListDetails() {
    // Workouts can only be in the range of 1 to 9
    // Warm Up Exercises has a max level of 3
    this.showHighestAlert = false;
    this.showLowestAlert = false;

    if (this.filteredMovement) {
      // Warm Up Exercises has a max level of 3
      if (
        this.scalingObject.movement.startsWith('WU') &&
        this.scalingObject.lLvl == 3
      ) {
        this.showHighestAlert = true;
      } else if (this.filteredMovement.level === this.scalingObject.lLvl) {
        this.showHighestAlert = true;
      } else if (
        this.filteredMovement.level &&
        this.scalingObject.lLvl === 1
      ) {
        this.showLowestAlert = true;
      }
    }
  }

  scalingUpdateCheck(leveling) {
    if (leveling == 'up') {
      this.scalingUpToggle = !this.scalingUpToggle;
      this.scalingDownToggle = false;
      if (this.scalingUpToggle) {
        this.scaleUp = 'check-mark.svg';
        this.scaleDown = 'scaling-down-arrow.svg';
      } else {
        this.scaleUp = 'scaling-up-arrow.svg';
      }
    } else if (leveling == 'down') {
      this.scalingDownToggle = !this.scalingDownToggle;
      this.scalingUpToggle = false;
      if (this.scalingDownToggle) {
        this.scaleDown = 'check-mark.svg';
        this.scaleUp = 'scaling-up-arrow.svg';
      } else {
        this.scaleDown = 'scaling-down-arrow.svg';
      }
    }
    if (this.scalingUpToggle || this.scalingDownToggle) {
      this.btnLvlClicked = leveling;
    } else {
      this.btnLvlClicked = '';
    }

    setTimeout(() => {
      this.intensityPrompt = false;
    }, 1000);
  }

  closeScaling() {
    this.intensityPrompt = false;
  }

  openScaling() {
    const pointer = this;
    this.intensityPrompt = true;
    if (pointer.spinnerTimerValue < 0) {
      this.clearSpinner();
    }
  }

  resetScalingScreenFlags() {
    // Reset Scaling Screen values to default
    this.scaleDown = 'scaling-down-arrow.svg';
    this.scaleUp = 'scaling-up-arrow.svg';
    this.dontAskAgain = !!this.dontAskAgain_movement.includes(
      this.scalingObject.movement.slice(0, 5)
    );
  }

  resetTimerFlags() {
    this.showOpenScaling = false;
    this.intensityPrompt = false;
    this.changeState();
    this.scalingDownToggle = false;
    this.scalingUpToggle = false;
    this.showHighestAlert = false;
    this.showLowestAlert = false;
    clearTimeout(this.openScalingTimer);
    this.clearSpinner();
  }

  disablePromptBtns(state = true) {
    // Set the state of the prompt buttons to prevent clicking more than once. Defaults to true if no parameters are passed.  Will change to false on parameter passed from the end of video changes
    this.btnDisabled = state;
    this.btnLvlClicked = ''; // Reset which button was clicked
    let promptButtons = document.querySelectorAll(".scaling-clicks");
    promptButtons.forEach(button => {
      // Remove all active classes
      button.classList.remove("active");
    })
  }

  startSpinTimmer(seconds: number) {
    let spinnerValue = 10;
    this.spinnerTimerValue = 100;

    const timer$ = interval(spinnerValue);
    let spinnerSubtract = this.spinnerTimerValue / ((1000 / spinnerValue) * seconds); // 100 divide by this.scalingScreenTimer * 2

    this.scalingTimer$ = timer$.subscribe(() => {
      this.spinnerTimerValue = this.spinnerTimerValue - spinnerSubtract;
      if (this.spinnerTimerValue < 0) {
        this.clearSpinner();
      }
    });
  }

  clearSpinner() {
    this.spinnerTimerValue = 100;
    clearInterval(this.spinnerTimer);
    this.scalingTimer$.unsubscribe();
  }

  checkBoxChecked() {
    let movement = this.scalingObject.movement.slice(0, 5);
    const pointer = this;
    this.dontAskAgain = !this.dontAskAgain;
    if (
      this.dontAskAgain &&
      !this.dontAskAgain_movement.includes(movement)
    ) {
      this.changePrompt = !this.changePrompt;
      this.dontAskAgain_movement.push(movement);
      setTimeout(() => {
        //Wait one second before executing
        pointer.intensityPrompt = false;
      }, 1000);
    } else {
      if (this.dontAskAgain_movement.includes(movement)) {
        this.arrayRemove(movement);
      }
    }
  }

  changeState() {
    this.currentState =
      this.currentState === 'initial' && this.intensityPrompt
        ? 'hidden'
        : 'initial';
  }

  arrayRemove(value) {
    const index = this.dontAskAgain_movement.indexOf(value);
    if (index > -1) {
      // only splice array when item is found
      this.dontAskAgain_movement.splice(index, 1); // 2nd parameter means remove one item only
    }
  }
}