import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare const fbq:Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'WeShape';

  constructor(private router: Router) {
  }
  ngOnInit() {
    this.router.events.subscribe((y: NavigationEnd) => {
      if(y instanceof NavigationEnd){
        fbq('track', 'PageView');
      }
    })
  }
}
