<div class="sms-modal">
    <div class="modal-container">
        <div class="modal-content">
            <span class="close" id="closeModal" (click)="closeModal()">&times;</span>
            <form [formGroup]="smsForm" (ngSubmit)="submitForm($event)">
                <header>{{ header }}</header>
                <p>{{ content }}</p>
                <div>
                    <input appPhoneMask type="tel" class="form-control py-3" id="phone_number" formControlName="phone_number" placeholder="Phone: (__) ___-___" />
                </div>
                <div class="sms-terms checkbox-txt-container">
                    <div class="checkbox-input-container">
                        <input class="termsAccepted" name="smsAccepted" formControlName="acceptTermsMobile" type="checkbox" [checked]="checkboxes.sms" (change)="checkCheckBoxvalue('sms')" />
                    </div>
                    <p>By providing my cell phone number and clicking 'Submit'. I agree to receive informational and marketing calls and texts to that number from WeShape that may be automatically dialed or prerecorded. Msg & Data Rates My Apply. Consent to receive texts is not a condition of purchase. I agree to WeShape's <a [href]="getWWWUrl('privacy-policy')" target="_blank">Privacy Policy</a> and <a [href]="getWWWUrl('terms-and-conditions')" target="_blank">Terms of Service</a>.</p>
                </div>
                <div class="sms-submission">
                    <button class="submit" [disabled]="!smsForm.valid || processing" type="submit">
                        <span *ngIf="!processing">Submit</span>
                        <span *ngIf="processing">Processing</span>
                    </button>
                    <p *ngIf="errorContent != ''" class="d-block invalid-feedback mt-3">{{ errorContent }}</p>
                </div>
            </form>
        </div>
    </div>
</div>