import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { SessionService, SurveyService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class FairyQuizUserResolver implements Resolve<boolean> {
  constructor(
    private quizService: SurveyService,
    private sessionService: SessionService,
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {

    let quizResponse = localStorage.getItem(this.sessionService.quizItemKey);

    if(quizResponse) {
      if (JSON.parse(quizResponse).versionSlug)
        this.sessionService.setQuizVersionSlug(JSON.parse(quizResponse).versionSlug);
      return of(true);
    }

    return this.quizService.getFairyQuizVersion().pipe(
      tap(res => {
        // Set the user's unique session token into storage to use for calls
        if (res)
          this.sessionService.setQuizVersionSlug(res.slug);

      }),
      catchError(error => {
        console.error(`Can't resolve user with fairy funnel quiz because of the error:`, error);
        // console.error();
        return of(null);
      }));
  }
}
