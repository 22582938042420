<section class="guarantee-section">
    <div class="guarantee">
        <header>30-Day Feel Better Guarantee</header>
        <div class="guarantee-content">
            <p> We are confident in the quality of our product – thousands of people have already changed their lives using WeShape. </p>
            <p> To achieve results, it's important to listen to your body and scale your workouts as needed. We are ready to return your money back if you don't feel better in at least </p>
            <p>30-days. For more information,</p>
            <p>please read our <a href="https://www.weshape.com/terms-and-conditions" target="_blank"><span class="terms-of-use">Terms of Use</span></a>
            </p>
        </div>
        <span class="sealcheck-icon" [inlineSVG]="'/assets/images/sealcheck.svg'"></span>
    </div>
    <div class="safety">
        <div class="information">
            <header>Your information is safe</header>
            <p> We won't sell or rent your personal contact information. </p>
        </div>
        <div class="information">
            <header>Secure checkout</header>
            <p> All information is encrypted and transmitted without risk. </p>
        </div>
        <div class="information">
            <header>Need help?</header>
            <div class="contact-us">
                <span>Send us an email: </span>
                <span class="contact-email">support@weshape.com</span>
            </div>
        </div>
    </div>
</section>