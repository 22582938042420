import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { SessionService } from '../services';

@Injectable({
  providedIn: 'root'
})

export class WeshapeTokenGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionService: SessionService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    /**
     * Route being triggered requires a weshape token to view.
     * Token will be in their query params or browser storage
     * Missing Token will redirect users back to homepage
     */
    
    return of(route.queryParams)
      .pipe(
        map(params => {
          let tokenState:boolean = false;
          let storageToken: string;
          // Check to see if we should override storing and token param based on other params
          // let ignoreTokenReplace = this.sessionService.hasIgnoredParams(params, this.sessionService.paypalParams);
          // Token Param found, set into local storage. Do not replace if certain params are found
          // if(params['token'] && !ignoreTokenReplace) {
          //   window.localStorage.setItem("weshape_token", JSON.stringify(params['token']));
          // }

          storageToken = localStorage.getItem("weshape_token");
          tokenState = !!(params['token'] || storageToken);
          if(!tokenState) {
            // No Valid Token found in query params or localstorage - redirect back to homepage
            this.router.navigate(['/']);
          }
          return tokenState;
        })
      )
  }
}
