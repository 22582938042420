<section class="pb-4">
  <form #challengeSignUp [formGroup]="challengeForm" (ngSubmit)="submitForm($event)">
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <input
            type="text"
            class="form-control py-3"
            id="firstName"
            formControlName="firstName"
            placeholder="First name*"
            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
            (change)="trimSpaces(challengeForm.get('firstName'))"
          />
          <div
            *ngIf="submitted && f.firstName.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.firstName.errors.required">
              Full name is required
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="mb-3">
          <input
            type="text"
            class="form-control py-3"
            id="lastName"
            formControlName="lastName"
            placeholder="Last name*"
            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
            (change)="trimSpaces(challengeForm.get('lastName'))"
          />
          <div
            *ngIf="submitted && f.lastName.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.lastName.errors.required">
              Last name is required
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <input
            type="email"
            class="form-control py-3"
            id="email"
            formControlName="email"
            placeholder="Email*"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            (change)="trimSpaces(challengeForm.get('email'))"
          />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.pattern">
              Please enter a valid email address
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="checkbox-txt-container">
      <input
        class="termsAccepted"
        name="termsAccepted"
        formControlName="acceptTerms"
        type="checkbox"
        [checked]="checkboxes.terms"
        (change)="checkCheckBoxvalue('terms')"
      />
      <p>I agree to the <a [href]="getWWWUrl('terms-and-conditions')" target="_blank">Terms and Conditions</a> and the <a [href]="getWWWUrl('challenge-rules')" target="_blank">Challenge Rules</a> for the Feel Good Challenge hosted by WeShape. I understand that only one application per participant will be accepted.</p>
    </div>
    <div class="text-center mt-3 text-20">
      <button class="submit-btn" type="submit" [disabled]="!challengeForm.valid || processing" >
        <span class="submit-btn-text" *ngIf="!processing">Sign Up</span>
        <span class="submit-btn-text" *ngIf="processing">Processing</span>
      </button>
      <p *ngIf="errorContent != ''" class="d-block invalid-feedback mt-3" [innerHTML]="errorContent"></p>
    </div>
  </form>
</section>